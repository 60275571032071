import { getInputVATRows, getInputVATColumns } from "./input-vat-statement";
import { getInputVat } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const inputVATFilters = {
  RecordType: "*",
};

const inputVATFilterOptions = [
  { key: "recordType", apiKey: "RecordType", keyType: "label" },
];

export default function ReportInputVAT({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vat-input"
      activeCompany={activeCompany}
      columnsDataProvider={getInputVATColumns}
      rowsDataProvider={getInputVATRows}
      fetchData={getInputVat}
      exportUrl="reports/export-query-report/vat-input"
      orderBy="CompanyName"
      filtersInitialState={inputVATFilters}
      filterOptions={inputVATFilterOptions}
    />
  );
}
