import { estimatedColumnWidth } from "../helpers";
import { formatCurrency } from "@/utils/helpers";

export default function getSupplierAgingColumns() {

  const colArr = [
    {
      label: "Company",
      value: true,
      columnId: "name",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Vendor Number",
      value: true,
      columnId: "supplierNumber",
      width: estimatedColumnWidth("number"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Vendor Name",
      value: true,
      columnId: "supplierName",
      width: estimatedColumnWidth("name"),
      resizable: true,
      formatter: (value) => String(value || "")
    },
    {
      label: "Advance Payment", value: true, columnId: "advancePayment", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => (value ? formatCurrency(String(value || "")) : "")
    },
    {
      label: "Not Due", value: true, columnId: "notDue", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "30 Days", value: true, columnId: "30Days", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "60 Days", value: true, columnId: "60Days", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "90 Days", value: true, columnId: "90Days", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "120 Days", value: true, columnId: "120Days", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Over 120 Days to 365 Days", value: true, columnId: "over120-365Days", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Over 12 Month to 18 Month", value: true, columnId: "over12-18Months", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Over 18 Month to 24 Months", value: true, columnId: "over18-24Months", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Over 2 Year to 3 Years", value: true, columnId: "over2-3Years", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Over 3 Year to above", value: true, columnId: "over3YearAndAbove", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
    {
      label: "Total", value: true, columnId: "total", width: estimatedColumnWidth("amount"), resizable: true, formatter: (value) => formatCurrency(String(value || "0"))
    },
  ];

  return colArr;
}
