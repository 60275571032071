import { useRef } from "react";

import { isEmpty } from "lodash";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";

function ExpenseForm() {
  const ref = useRef(null);

  const updateLineItem = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, tax: parentTax, expenseDetail, expenseDetailTable } = formState;
    if (expenseDetailTable) {
      const { amount } = expenseDetailTable;
      const { taxAmount: prevTaxAmount } = expenseDetailTable;
      let taxAmount = 0;

      let principalAmountValue = 0;

      const selectedTax = parentTax;

      if (selectedTax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax: selectedTax,
          taxAmount: "",
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);
        principalAmountValue = principalAmount;
        taxAmount = calculatedTaxAmount;
      }
      expenseDetailTable.amount = amount;
      expenseDetailTable.tax = selectedTax;
      expenseDetailTable.taxAmount = taxAmount;
      expenseDetailTable.totalAmount = formatDecimalValues(
        Number(principalAmountValue || amount) + Number(taxAmount)
      );
    }
    if (expenseDetail) {
      const updatedLineItem = expenseDetail?.map((rent) => {
        const { amount } = rent;
        const { tax, taxAmount: prevTaxAmount } = rent;
        let taxAmount = 0;
        let principalAmountValue = 0;

        let selectedTax = tax ?? parentTax;
        if (key === "tax") {
          selectedTax = parentTax;
        }

        if (selectedTax && amountOfTax) {
          const parameters = {
            amount,
            amountOfTax,
            tax: selectedTax,
            taxAmount: "",
          };

          if (key === "amountOfTax") {
            parameters.taxAmount = prevTaxAmount || 0;
          }

          const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

          principalAmountValue = principalAmount;
          taxAmount = calculatedTaxAmount;
        }

        return {
          ...rent,
          amount,
          tax: selectedTax,
          taxAmount,
          totalAmount: formatDecimalValues(
            Number(principalAmountValue || amount) + Number(taxAmount)
          ),
        };
      });

      ref.current.setFormValue("expenseDetail", updatedLineItem);
      ref.current.setFormValue("expenseDetailTable", expenseDetailTable);
    }
  };

  const setAdvanceAmount = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;

    const { advanceAmount, supplierBalance, subtotal, total } = formState;

    if (!supplierBalance || !subtotal) {
      ref.current.setFormState({
        advanceAmount: 0,
      });
      return;
    }

    const isAmountMoreThanTotal = advanceAmount > subtotal;
    const isAmountMoreThanBalance = advanceAmount > supplierBalance;

    if (isAmountMoreThanBalance) {
      const openBalance = total - supplierBalance;
      ref.current.setFormState({
        advanceAmount: supplierBalance,
        openBalance,
      });
      return;
    }

    if (isAmountMoreThanTotal) {
      const openBalance = total - subtotal;
      ref.current.setFormState({
        advanceAmount: subtotal,
        openBalance,
      });
      return;
    }

    const openBalance = total - Number(advanceAmount);
    ref.current.setFormValue("openBalance", openBalance);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { expenseDetail, amountOfTax } = formState;

    const subtotal = expenseDetail?.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const data = {
      subtotal,
      taxAmount: "",
      total: "",
    };

    if (amountOfTax && expenseDetail.tax) {
      const parameters = {
        amount: subtotal,
        amountOfTax,
        tax: expenseDetail.tax,
        taxAmount: "",
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    const taxValue = expenseDetail?.reduce(
      (t, currentValue) => t + Number(currentValue.taxAmount),
      0
    );
    const totalValue = expenseDetail?.reduce(
      (t, currentValue) => t + Number(currentValue.totalAmount),
      0
    );

    data.taxAmount = taxValue;
    data.total = formatDecimalValues(totalValue);
    data.openBalance = formatDecimalValues(totalValue);
    ref.current.setFormState(data);
    updateLineItem(key, value);
    setAdvanceAmount(key, value);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tax":
      case "expenseDetail":
      case "amountOfTax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "vendor":
        if (value) {
          const vendorState = {
            tRN: value?.tRN,
            supplierBalance: value?.openingBalance ?? 0,
          };
          ref.current.setFormState(vendorState);
        } else {
          ref.current.setFormState({
            tRN: "",
            supplierBalance: 0,
          });
        }
        break;

      case "advanceAmount": {
        setAdvanceAmount(key, value);
        break;
      }

      default:
        break;
    }
  };

  const onChildStateChange = ({ index, key, value, parentField, parentFieldType }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "expenseDetail") {
      switch (key) {
        case "expenseType": {
          parentFieldState[key] = value;

          if (value) {
            const { expenseAccount } = value;

            if (expenseAccount && !isEmpty(expenseAccount)) {
              parentFieldState.account = {
                label: expenseAccount.name,
                value: expenseAccount.id,
              };
            }
          }

          if (!value) {
            parentFieldState.account = null;
          }
          break;
        }
        case "quantity":
        case "rate":
        case "tax": {
          parentFieldState[key] = value;
          const { quantity, rate, tax } = parentFieldState;

          const amount = formatDecimalValues(quantity * rate);
          parentFieldState.amount = amount;

          const { amountOfTax } = formState;

          if (tax && amountOfTax) {
            const parameters = {
              amount: parentFieldState.amount,
              amountOfTax,
              tax,
              taxAmount: "",
            };
            const { taxAmount, principalAmount } = calculateTax(parameters);
            parentFieldState.taxAmount = taxAmount;
            parentFieldState.totalAmount = Number(principalAmount) + Number(taxAmount);
          }
          break;
        }

        default:
          break;
      }
    }
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("ExpenseObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onChildStateChange={onChildStateChange}
      />
    </BoxedContent>
  );
}

export default ExpenseForm;
