import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input, FormControl, Button } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { subYears } from "date-fns";
import qs from "qs";
import {
  CustomizedDateRangePicker, IconButton, SvgIcon, CustomDropdown
} from "@/components/common";
import { formatApiPayloadDate } from "@/utils/helpers";
import { ReportFilterDrawer } from "@/components/drawers";
import appSettings from "@/settings";
import { getSupplierPaymentColumns, getSupplierPaymentRows } from "./supplier-payments";
import { getSupplierPayment } from "@/api/finance/reportApi";

const reportType = [
  { label: "Paid & Outstanding", value: "*" },
  { label: "Paid Report", value: "PaidReport" },
  { label: "Outstanding Report", value: "OutstandingReport" },
];

function ReportSupplierPayment({ activeCompany }) {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [dateRange, setDateRange] = useState([subYears(new Date(), 1), new Date()]);
  const [filters, setFilters] = useState({
    SupplierKey: "*",
    ReportType: reportType[0].value,
    reportType: reportType[0],
    StartDate: formatApiPayloadDate(dateRange[0]),
    EndDate: formatApiPayloadDate(dateRange[1]),
  });

  const onClose = (value) => {
    setIsOpen(value);
  };

  const [paymentColumnsData, setPaymentColumnsData] = useState(
    getSupplierPaymentColumns(ref?.current?.clientWidth)
  );

  const formatFilters = (data) => {
    const filterData = {
      SupplierKey: "*",
      ReportType: reportType[0].value,
      StartDate: formatApiPayloadDate(dateRange[0]),
      EndDate: formatApiPayloadDate(dateRange[1]),
    };

    if (data.reportType) {
      filterData.ReportType = data.reportType.value;
    }
    if (data.supplierKey) {
      filterData.SupplierKey = data.supplierKey.value;
    }
    if (data?.company) {
      filterData.Company = data?.company?.companySettings?.name;
    }

    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/supplier-payment?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: supplierPaymentsData } = useQuery(["supplier-payments", dateRange, filters, reportType], () => getSupplierPayment({ ...formatFilters(filters) }));
  const columns = useMemo(() => paymentColumnsData.filter((c) => c.value), [paymentColumnsData]);

  const formatSupplierPaymentsReport = (transaction) => {
    if (filterText) {
      return transaction.filter(
        (obj) => obj.paymentNumber.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const rows = useMemo(
    () =>
      getSupplierPaymentRows({
        data: {
          data: formatSupplierPaymentsReport(supplierPaymentsData?.data || []),
        },
        columns,
      }),
    [supplierPaymentsData?.data, columns, filterText]
  );

  const handleColumnResize = (ci, width) => {
    setPaymentColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by payment number"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={paymentColumnsData} setColumnData={setPaymentColumnsData} />
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer filters={filters} setFilters={setFilters} type="supplier-payments" size={400} isOpen={isOpen} onClose={onClose} />
        </div>
      </div>
      <div className="react-grid-container">
        <h6><b>Supplier Payments</b></h6>
        <ReactGrid
          key={filterText}
          rows={rows}
          columns={columns}
          stickyTopRows={1}
          enableFillHandle
          enableRangeSelection
          onColumnResized={handleColumnResize}
          disableVirtualScrolling
        />
      </div>
    </div>
  );
}

export default ReportSupplierPayment;
