import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase, startCase } from "lodash";
import { Button } from "@hydra/atom/components";

import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getPaymentsTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";

const formatContract = (contract) => {
  const data = {
    agreementStartDate: new Date(contract.agreementStartDate),
    contract: {
      label: contract.number,
      value: contract.id,
    },
    building: {
      label: contract?.building?.name,
      value: contract?.building?.id,
    },
    unit: contract?.unit.map((unit) => ({
      label: unit?.name,
      value: unit?.id,
    })),
    reservation: {
      label: contract?.reservation?.number,
      value: contract?.reservation?.id,
      total: contract?.reservation?.total,
    },
    credit: contract?.reservation?.total || "",
    isSelected: false,
  };

  if (contract.amountOfTax) {
    data.amountOfTax = {
      label: startCase(contract.amountOfTax),
      value: contract.amountOfTax,
    };
  }

  if (contract.salesLocation && !isEmpty(contract.salesLocation)) {
    data.salesLocation = {
      label: contract.salesLocation?.name,
      value: contract.salesLocation?.id,
    };
  }

  if (contract.tax && !isEmpty(contract.tax)) {
    data.tax = {
      ...contract.tax,
      label: contract.tax?.name,
      value: contract.tax?.id,
    };
  }

  data.rentalDetail = contract.paymentDetail
    .filter((p) => !p.invoice)
    .map((payment) => ({
      key: payment.id,
      contract: data.contract,
      building: data.building,
      unit: data.unit,
      paymentDate: new Date(payment.paymentDate),
      chequeNo: payment.chequeNo,
      utilityAmount: payment.utilityAmount,
      securityDepositAmount: payment.securityDepositAmount,
      adminChargesAmount: payment.adminChargesAmount,
      rentAmount: payment.rentAmount,
      amount: payment.amount,
      openBalance: payment.openBalance,
      paymentMethod: {
        label: startCase(payment.paymentMethod),
        value: payment.paymentMethod,
      },
      tax: {
        label: payment.tax?.name,
        value: payment.tax?.id,
        saleRate: payment?.tax?.saleRate,
      },
      taxAmount: payment.taxAmount,
      totalAmount: payment.totalAmount,
      status: payment.paymentStatus,
      paymentDetailId: payment.id,
      description: payment.description
    }))
    .sort((a, b) => b.paymentDate - a.paymentDate);

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {
        showButton ? (
          <Button small bordered onClick={openModal}>
            Select payments
          </Button>
        ) : null
      }
    </defaultComponents.HeaderRightContent>
  );
}

function RentalInvoiceForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [paymentsTableData, setPaymentsTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: contractData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ContractObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("ContractObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 5,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep"
      }),
    {
      enabled: Boolean(state?.tenant?.value),
    }
  );

  useEffect(() => {
    if (contractData && contractData?.data && state?.tenant) {
      const formattedContract = contractData.data.map((receipt) => formatContract(receipt));
      if (contractData?.data?.length) {
        setPaymentsTableData(formattedContract[0].rentalDetail);
        setState((prevState) => ({
          ...prevState,
          contract: formattedContract[0],
        }));
      } else {
        setPaymentsTableData([]);
        setState((prevState) => ({
          ...prevState,
          contract: null
        }));
      }
      openModal();
    }
  }, [contractData, state?.tenant?.value]);

  const setRentalDetail = (rentalDetail) => {
    const { credit, tax, salesLocation, amountOfTax } = state.contract;

    const amountToTax = rentalDetail.filter((p) => p?.tax?.saleRate).reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.amount),
      0
    );

    const totalAmount = rentalDetail.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const taxAmount = rentalDetail.reduce(
      (total, currentValue) => total + Number(currentValue.taxAmount),
      0
    );

    const pendingPayments = rentalDetail.filter((p) => !["Collected", "Cleared"].includes(p.status));

    const balanceDue = pendingPayments.reduce(
      (total, currentValue) => total + Number(currentValue.openBalance || 0),
      0
    );

    const pdcAmount = rentalDetail.filter((p) => {
      const { paymentMethod } = p;

      if (paymentMethod.value === "Cheque") {
        return true;
      }

      return false;
    }).reduce(
      (total, currentValue) => total + Number(currentValue.totalAmount || 0),
      0
    );

    ref.current.setFormState({
      tax,
      salesLocation,
      amountOfTax,
      rentalDetail,
      subtotal: totalAmount,
      amountBeforeTax: amountToTax,
      taxAmount,
      credit,
      balanceDue,
      pdcAmount,
      total: formatDecimalValues(totalAmount + Number(taxAmount)),
    });
  };

  const updateRentalDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, rentalDetail } = formState;

    const updatedRentalDetail = rentalDetail.map((rent) => {
      let { amount } = rent;
      const { tax, taxAmount: prevTaxAmount } = rent;
      let taxAmount = 0;

      if (tax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax,
          taxAmount: ""
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      return {
        ...rent,
        amount,
        tax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      };
    });

    ref.current.setFormValue("rentalDetail", updatedRentalDetail);
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { subtotal, tax, amountOfTax } = formState;

    const data = {
      subtotal,
      taxAmount: "",
      total: "",
    };

    if (amountOfTax && tax) {
      const parameters = {
        amount: subtotal,
        amountOfTax,
        tax,
        taxAmount: ""
      };

      const { taxAmount, principalAmount } = calculateTax(parameters);

      data.taxAmount = taxAmount;
      data.amountBeforeTax = principalAmount;
    }

    data.total = Number(data.amountBeforeTax) + Number(data.taxAmount);
    ref.current.setFormState(data);
    updateRentalDetail(key, value);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        ref.current.setFormValue("tRN", value?.tRN);
        break;

      case "tax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTaxAndTotal(key, value);
        break;
      }

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (paymentsTableData.length) {
      const rentalDetail = paymentsTableData.filter((r) => r.isSelected);
      setRentalDetail(rentalDetail);
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant Payments"
        subtitle="Selected tenant has following pending payments"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {paymentsTableData.length ? (
          <TableWithCheckbox
            data={paymentsTableData}
            setData={setPaymentsTableData}
            columns={getPaymentsTableColumns()}
            allowMultiple
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("RentalInvoiceObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        components={{
          HeaderRightContent: (props) => HeaderRightContent({
            openModal,
            showButton: Boolean(paymentsTableData.length),
            ...props
          })
        }}
      />
    </BoxedContent>
  );
}

export default RentalInvoiceForm;
