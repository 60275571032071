import {
  BoxedContent, Header, HeaderLeftContent
} from "@/components/common";
import { getVATSummaryRows,
  getVATSummaryColumns,
  getVATOutputSummaryColumns,
  getVATOutputSummaryRows } from "./vat-summary-statement";
import { getVATSummaryReport } from "@/api/finance/reportApi";

import {
  DynamicReport
} from "@/components/dynamic";

const vatInputFilters = {
  ReportType: "VAT Input"
};

const vatOutputFilters = {
  ReportType: "VAT Output"
};

export default function ReportVATSummary({ activeCompany }) {
  return (
    <>
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Input VAT" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="vat-summary"
          activeCompany={activeCompany}
          columnsDataProvider={getVATSummaryColumns}
          rowsDataProvider={getVATSummaryRows}
          fetchData={getVATSummaryReport}
          exportUrl="reports/export-query-report/vat-summary"
          filtersInitialState={vatInputFilters}
          orderBy="VATType"
        />
      </BoxedContent>
      <BoxedContent>
        <Header
          leftContent={<HeaderLeftContent title="Output VAT" subtitle="" icon="agent-icon-2" />}
        />
        <DynamicReport
          reportName="vat-summary"
          activeCompany={activeCompany}
          columnsDataProvider={getVATOutputSummaryColumns}
          rowsDataProvider={getVATOutputSummaryRows}
          fetchData={getVATSummaryReport}
          exportUrl="reports/export-query-report/vat-summary"
          filtersInitialState={vatOutputFilters}
          orderBy="VATType"
        />
      </BoxedContent>
    </>
  );
}
