import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Pagination,
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";

import qs from "qs";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getVATDetailColumns, getVATDetailRows } from "./vat-detail-statement";
import { ReportFilterDrawer } from "@/components/drawers";
import { getVatDetails } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportVATDetail({ activeCompany }) {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [columnsData, setColumnsData] = useState(getVATDetailColumns(ref?.current?.clientWidth));
  const [filters, setFilters] = useState({
    LocationId: "*",
    Location: "*",
    CompanyIdParam: "*",
    Company: "*",
    CompanyName: "*",
  });
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/vat-details?CompanyId=${activeCompany.id}&${qs.stringify(filters)}`, "_blank", "noopener,noreferrer");
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction?.filter((obj) =>
        obj?.customerName.toLowerCase().includes(filterText?.toLowerCase()));
    }
    return transaction;
  };

  const formatFilters = (data) => {
    const filterData = {
      LocationId: "*",
      Location: "*",
      CompanyIdParam: "*",
      Company: "*",
      CompanyName: "*",
    };

    if (data?.location) {
      filterData.LocationId = data.location.id;
    }

    if (data?.company) {
      filterData.Company = data?.company?.companySettings?.name;
    }

    return filterData;
  };

  const { data: vatData, isLoading } = useQuery(["vat-exception", currentPage, perPage.value, filters], () => getVatDetails({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "Building",
    ...formatFilters(filters)
  }));
  const totalCount = useMemo(() => vatData?.totalCount ||
  0, [vatData]);

  const onClose = (value) => {
    setIsOpen(value);
  };

  const rows = useMemo(
    () =>
      getVATDetailRows({
        data: {
          data: formatReport(vatData?.data) || [],
        },
        columns,
      }),
    [vatData, columns, filterText]
  );

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-9 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer filters={filters} setFilters={setFilters} type="vat-details" size={400} isOpen={isOpen} onClose={onClose} />
        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportVATDetail;
