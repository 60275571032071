import {
  useCallback, useEffect, useMemo, useState
} from "react";
import PropTypes from "prop-types";
import qs from "qs";
import { useQuery } from "@tanstack/react-query";
import {
  startCase, find, kebabCase, isEmpty
} from "lodash";
import {
  useLocation, useNavigate, useParams, useSearchParams
} from "react-router-dom";
import pluralize, { singular } from "pluralize";
import { useSelector } from "react-redux";
import { camelize, pascalize } from "humps";
import { Button, Loader, ReactSelect } from "@hydra/atom/components";
import { selectActiveCompany, selectActiveApp } from "@/store/appSlice";
import { useIsMobile } from "@/hooks";
import {
  BoxedContent,
  Header,
  NoDataFound,
  RequirePermission,
  SvgIcon,
  IconButton,
  CustomDropdown,
  TabWithStatus
} from "@/components/common";
import { DynamicObjectOverviewTable } from "@/components/dynamic";
import {
  getDynamicObjectOverviewTableData,
  getDynamicObjectOverviewTableColumns,
  filterFieldColumn,
} from "@/components/dynamic/dynamicObjectOverviewTableData";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { skeletonLoadingRows, formatApiPayloadDate } from "@/utils/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

import { getUserLoginId } from "@/api/user/authApi";
import { selectPermissions } from "@/store/userSlice";
import {
  prepareFieldValue,
  checkDynamicObjectPermission,
  transformObject,
} from "@/utils/dynamic/helpers";
import DynamicFiltersDrawer from "@/components/dynamic/DynamicFiltersDrawer";
import appSettings from "@/settings";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function TableHeader({
  label,
  areFiltersSelected,
  fields,
  filters,
  setFilters,
  isFiltersDrawerOpen,
  setIsFiltersDrawerOpen,
  onFilterSearch,
  onClearFilters,
  showFilters,
  objectName,
  queryFilters,
  showExportButton,
  isChild
}) {
  const activeCompany = useSelector(selectActiveCompany);
  const drawerFields = useMemo(() => {
    if (objectName === "Employee") {
      return fields.filter(
        (item) =>
          item.camelizedName !== "firstName" &&
          item.camelizedName !== "middleName" &&
          item.camelizedName !== "lastName"
      );
    }

    if (isChild) {
      const parentObjectName = objectName.split("_")[0];

      if (parentObjectName) {
        const lookupField = {
          name: "detailId",
          camelizedName: "detailId",
          label: startCase(parentObjectName),
          lookupObjectName: parentObjectName,
          lookupDisplayFieldName: ["Number"],
          objectFieldType: "Lookup",
          searchFilter: true
        };

        return [
          lookupField,
          ...fields
        ];
      }
    }
    return fields;
  }, []);

  const exportReport = () => {
    let finalObjectName = kebabCase(pluralize(objectName));
    if (finalObjectName === "pdcs") {
      finalObjectName = "pd-cs";
    }
    if (filters && areFiltersSelected) {
      const clonedQueryFilters = { ...queryFilters };
      transformObject(clonedQueryFilters);
      const params = new URLSearchParams(clonedQueryFilters);
      const url = `${appSettings.baseUrl}/object-export/${kebabCase(
        pluralize(finalObjectName)
      )}/filtered?Company[like]=${activeCompany.id}&${params}`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      window.open(
        `${appSettings.baseUrl}/object-export/${kebabCase(
          pluralize(finalObjectName)
        )}/filtered?Company[like]=${activeCompany.id}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  };

  return (
    <div className="filters-row table-view-filters-row">
      <div className="left-content">
        <p className="headline">{label}</p>
      </div>
      {showFilters && (
        <DynamicFiltersDrawer
          fields={drawerFields}
          filters={filters}
          setFilters={setFilters}
          isOpen={isFiltersDrawerOpen}
          onClose={setIsFiltersDrawerOpen}
          onSearch={onFilterSearch}
        />
      )}
      <div className="right-content justify-content-end">
        {showExportButton && (
          <IconButton className="icon-button-with-text" onClick={() => exportReport()}>
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
        )}
        {showFilters && (
          <>
            {areFiltersSelected && (
              <IconButton
                className="icon-button-with-text btn-icon-delete"
                onClick={onClearFilters}
              >
                <SvgIcon icon="delete-stroke-icon" />
                <span>Clear filters</span>
              </IconButton>
            )}
            <IconButton
              className="icon-button-with-text"
              onClick={() => setIsFiltersDrawerOpen(true)}
            >
              <SvgIcon icon="filter-lines-stroke-icon" />
              <span>Filters</span>
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
}

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  areFiltersSelected: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  isFiltersDrawerOpen: PropTypes.bool.isRequired,
  setIsFiltersDrawerOpen: PropTypes.func.isRequired,
  onFilterSearch: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  objectName: PropTypes.string.isRequired,
  queryFilters: PropTypes.object.isRequired,
  isChild: PropTypes.bool.isRequired,
};

function HeaderLeftContent({ title, subtitle, icon }) {
  return (
    <div className="header-left-content">
      {icon && (
        <div className="icon-container">
          <SvgIcon icon={icon} />
        </div>
      )}
      <div>
        <h1>{title}</h1>
        {subtitle && <p className="sub-title">{subtitle}</p>}
      </div>
    </div>
  );
}

HeaderLeftContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
};

HeaderLeftContent.defaultProps = {
  subtitle: null,
  icon: null,
};

function HeaderRightContent({
  objectName,
  onClickNew,
  shownColumns,
  setShownColumns,
  showActionButton,
  children,
}) {
  const isMobileView = useIsMobile();

  return isMobileView ? (
    <div className="buttons-at-end flex-column mobile-responsive-custom-buttons">
      {children}
      <RequirePermission parent="Model" scope={objectName} action="View">
        <CustomDropdown
          data={shownColumns}
          setColumnData={setShownColumns}
          buttonText="Customize Columns"
        />
      </RequirePermission>
      {showActionButton && (
        <RequirePermission parent="Model" scope={objectName} action="Insert">
          <Button small onClick={onClickNew} className="btn-with-icon">
            <Button.Prepend>
              <SvgIcon icon="plus-icon" />
            </Button.Prepend>
            {startCase(objectName)}
          </Button>
        </RequirePermission>
      )}
    </div>
  ) : (
    <div className="buttons-at-end">
      {children}
      <RequirePermission parent="Model" scope={objectName} action="View">
        <CustomDropdown
          objectName={objectName}
          data={shownColumns}
          setColumnData={setShownColumns}
          buttonText="Customize Columns"
        />
      </RequirePermission>
      {showActionButton && (
        <RequirePermission parent="Model" scope={objectName} action="Insert">
          <Button small onClick={onClickNew} className="btn-with-icon">
            <Button.Prepend>
              <SvgIcon icon="plus-icon" />
            </Button.Prepend>
            {startCase(objectName)}
          </Button>
        </RequirePermission>
      )}
    </div>
  );
}

HeaderRightContent.propTypes = {
  objectName: PropTypes.string,
  onClickNew: PropTypes.func,
  showActionButton: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.oneOf([null])]),
};

HeaderRightContent.defaultProps = {
  objectName: "",
  onClickNew: () => {},
  showActionButton: true,
  children: null,
};

function DynamicObjectOverviewBody({ objectName, children }) {
  return <div className={`dynamic-object-overview-body ${objectName}-body`}>{children}</div>;
}

DynamicObjectOverviewBody.propTypes = {
  objectName: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.oneOf([null])]),
};

DynamicObjectOverviewBody.defaultProps = {
  objectName: "",
  children: null,
};

export const defaultComponents = {
  HeaderRightContent,
  HeaderLeftContent,
  DynamicObjectOverviewBody,
};

function DynamicObjectOverview({
  showTableOnly,
  showActionCell,
  filters,
  objectName: objectNameFromProps,
  showPagination,
  noDataHideButton,
  showCreatedAtCell,
  navigationLink,
  showFilters,
  tabs,
  components,
  showBreadcrumb,
  showActionButton,
  showExportButton = true,
  title,
}) {
  const navigate = useNavigate();
  const { objectName: pluralObjectName } = useParams();
  const [searchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [sortByColumns, setSortByColumns] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeApp = useSelector(selectActiveApp);
  const isMobileView = useIsMobile();
  const isMobileView800 = useIsMobile(992);
  const objectName = objectNameFromProps || singular(pluralObjectName || "");
  const [drawerFilters, setDrawerFilters] = useState({});
  const [queryFilters, setQueryFilters] = useState({});
  const [isFiltersDrawerOpen, setIsFiltersDrawerOpen] = useState(false);
  const [shownColumns, setShownColumns] = useState([]);
  const permissions = useSelector(selectPermissions);
  const hasViewPermission = checkDynamicObjectPermission({
    permissions,
    scope: pascalize(objectName ?? ""),
    action: "View",
  });
  const location = useLocation();
  const isReportUrl = location.pathname.includes("report");

  const navigationRouteLink = `/${kebabCase(activeApp?.value)}/${kebabCase(objectName)}/new`;

  const shownComponents = {
    ...defaultComponents,
    ...components,
  };

  const { data, isLoading } = useQuery(
    ["dynamic-object-camelized-schema", kebabCase(objectName)],
    () => getDynamicObjectByNameWithCamelizedFieldNames(objectName)
  );

  const showActionButtonState = useMemo(() => {
    if (objectName === "move-out-request") {
      return false;
    }
    if (objectName === "move-in-request") {
      return false;
    }
    return showActionButton;
  }, [objectName]);

  const searchFields = useMemo(() => {
    if (!data || !data?.document) return [];

    const excludeFields = ["Time", "Checkbox", "Table", "Attachment", "DateTime", "Address"];

    const includeSearchField = ["Text"];
    const excludeGroupName = ["Address", "PermanentAddress"];

    return data.document
      .filter(
        (item) =>
          !excludeFields.includes(item.objectFieldType) &&
          !item.readOnly &&
          !excludeGroupName.includes(item.groupName)
      )
      .map((field) => {
        const foundColumn = shownColumns.find((column) => column.fieldName === field.name);
        if (foundColumn) {
          return {
            ...field,
            searchField: includeSearchField.includes(field.objectFieldType),
            searchFilter: true,
          };
        }

        return field;
      });
  }, [data, shownColumns]);

  const tabOptions = useMemo(() => {
    if (tabs.length) {
      return tabs;
    }

    if (data?.document) {
      let tabField = data.document.find((f) => f.tabFilter);

      if (!tabField) {
        tabField = data.document.find(
          (f) => f.camelizedName === "status" && f.objectFieldType === "Select"
        );
      }

      if (tabField) {
        const allOption = { label: "All", value: undefined, fieldName: tabField.camelizedName };
        const filteredOptions = tabField.options.filter((o) => o.tabOption);

        if (filteredOptions.length) {
          return [
            allOption,
            ...filteredOptions.map((o) => ({
              ...o,
              fieldName: tabField.camelizedName,
            })),
          ];
        }

        return [
          allOption,
          ...tabField.options.map((o) => ({
            ...o,
            fieldName: tabField.camelizedName,
          })),
        ];
      }

      return [];
    }

    return [];
  }, [data]);

  const activeTab = tabOptions[activeTabIndex];

  const onLoadFilterSearch = (fromParams) => {
    const apiFilters = {};
    const includeSearchField = ["autonumber", "text", "phone", "calculated"];

    searchFields.forEach((field) => {
      if (field.searchField || includeSearchField.includes(field.objectFieldType.toLowerCase())) {
        if (fromParams[field.name]) {
          apiFilters[`${field.name}[like]`] = `'%${fromParams[field.name]}%'`;
        }
      } else if (
        field.objectFieldType.toLowerCase() === "date" &&
        Array.isArray(fromParams[field.name])
      ) {
        if (fromParams[field.name][0]) {
          apiFilters[`${field.name}[gte]`] = formatApiPayloadDate(
            new Date(fromParams[field.name][0])
          );
        }
        if (fromParams[field.name][1]) {
          apiFilters[`${field.name}[lte]`] = formatApiPayloadDate(
            new Date(fromParams[field.name][1])
          );
        }
      } else if (fromParams[field.name]) {
        apiFilters[field.name] = prepareFieldValue(field, fromParams, false, true);
      }
    });

    if (fromParams.page) {
      setCurrentPage(fromParams.page);
    } else {
      setCurrentPage(1);
    }

    if (fromParams.pageSize) {
      setPerPage(find(perPageOptions, { label: fromParams.pageSize }));
    }

    if (fromParams.detailId) {
      apiFilters.detailId = apiFilters.detailId.value;
    }

    setQueryFilters(apiFilters);
    setDrawerFilters(fromParams);
    setActiveTabIndex(fromParams.activeTabIndex ?? 0);
    setIsFiltersDrawerOpen(false);
  };

  const parseUrlParams = () => {
    const queryParams = qs.parse(window.location.search?.split("?")[1]);
    if (queryParams) {
      try {
        const decodedFilter = queryParams.filter;
        if (decodedFilter) {
          onLoadFilterSearch(decodedFilter);
        }
      } catch (error) {
        console.error("Error parsing filter from URL:", error);
      }
    }
  };

  useEffect(() => {
    const queryParams = qs.parse(window.location.search?.split("?")[1]);
    if (queryParams && searchFields) {
      parseUrlParams();
    }
  }, [searchParams, searchFields]);

  const onFilterSearch = (selectedTabIndex = null) => {
    const apiFilters = {};
    const includeSearchField = ["autonumber", "text", "phone", "calculated"];

    searchFields.forEach((field) => {
      if (field.searchField || includeSearchField.includes(field.objectFieldType.toLowerCase())) {
        if (drawerFilters[field.name]) {
          apiFilters[`${field.name}[like]`] = `'%${drawerFilters[field.name]}%'`;
        }
      } else if (
        field.objectFieldType.toLowerCase() === "date" &&
        Array.isArray(drawerFilters[field.name])
      ) {
        drawerFilters[field.name].forEach((selectedDate, index) => {
          if (selectedDate && index === 0) {
            apiFilters[`${field.name}[gte]`] = formatApiPayloadDate(selectedDate);
          }

          if (selectedDate && index === 1) {
            apiFilters[`${field.name}[lte]`] = formatApiPayloadDate(selectedDate);
          }
        });
      } else if (drawerFilters[field.name]) {
        apiFilters[field.name] = prepareFieldValue(field, drawerFilters, false, true);
      }
    });

    if (drawerFilters.detailId && !isEmpty(drawerFilters.detailId)) {
      apiFilters.detailId = drawerFilters.detailId.value;
    }

    const filter = {
      ...drawerFilters,
      page: 1,
      pageSize: perPage.value,
    };

    if (typeof selectedTabIndex === "number") {
      filter.activeTabIndex = selectedTabIndex;
    }

    const queryParams = qs.stringify({
      filter,
    });

    navigate(`?${queryParams}`);
    setQueryFilters(apiFilters);
    setDrawerFilters(drawerFilters);
    setCurrentPage(1);
    setIsFiltersDrawerOpen(false);
  };

  useEffect(() => {
    setDrawerFilters({});
    setQueryFilters({});
    parseUrlParams();
  }, [objectName]);

  const onClearFilters = () => {
    setDrawerFilters({});
    setQueryFilters({});
    navigate(window.location.pathname);
  };

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
    const queryParams = qs.stringify({
      filter: {
        ...drawerFilters,
        page: pageNumber,
        pageSize: pageSize.value,
      },
    });
    navigate(`?${queryParams}`);
  };

  const getSortOrder = () => {
    if (sortByColumns.length) {
      return sortByColumns[0]?.desc ? "DESC" : "ASC";
    }

    return "DESC";
  };

  const prepareFilters = useCallback(() => {
    const tabFilter = {};

    if (!isEmpty(activeTab)) {
      if (activeTab.multiple) {
        activeTab.filters.forEach((filter) => {
          tabFilter[filter.fieldName] = filter.value;
        });
      } else {
        tabFilter[activeTab.fieldName] = activeTab.value;
      }
    }

    if (
      kebabCase(objectName) === kebabCase(dynamicObjectMap.get("ServiceRequestObjectName")) ||
      kebabCase(objectName) === kebabCase(dynamicObjectMap.get("ReservationObjectName")) ||
      kebabCase(objectName) === kebabCase(dynamicObjectMap.get("CustomerQuotationObjectName"))
    ) {
      return {
        ...filters,
        ...tabFilter,
        queryMode: "Deep",
      };
    }

    return {
      ...filters,
      ...tabFilter,
    };
  }, [filters, activeTab, objectName]);

  const compareData = (oldData, newData) => {
    if (!oldData || !Array.isArray(newData.data)) return newData;
    const oldIds = oldData?.data?.map((item) => item?.id);
    const newDataNotInOld = newData?.data?.filter((item) => !oldIds?.includes(item?.id));
    const userId = getUserLoginId();
    const newIds = newDataNotInOld?.map((item) => item?.id);
    const final = newData?.data?.map((item) => {
      if (newIds.includes(item.id) && item.createdById === parseInt(userId, 10)) {
        return { ...item, isNewCreated: true };
      }
      return item;
    });
    return { ...newData, data: final };
  };

  const { data: values, isLoading: isLoadingValues } = useQuery(
    [
      kebabCase(objectName),
      currentPage,
      perPage.value,
      sortByColumns,
      activeTab,
      filters,
      queryFilters,
    ],
    () =>
      getDynamicObjectRecords(objectName, {
        take_page: currentPage,
        limit_page: perPage.value,
        sort_by: sortByColumns.length ? sortByColumns[0].id : "CreatedAt",
        sort_type: getSortOrder(),
        ...prepareFilters(),
        ...queryFilters,
      }),
    {
      structuralSharing: (oldData, newData) => compareData(oldData, newData),
    }
  );

  const listViewFields = useMemo(() => {
    if (data) {
      const listView = data?.layout.forms.find(({ dataType }) => dataType === "ListFormModel");
      let tableColumns = data?.document
        .filter((f) => filterFieldColumn(f))
        .map((c) => ({
          label: c.label,
          fieldName: c.camelizedName,
          value: true,
        }));

      if (listView) {
        const { listFormViews } = listView;
        let columnFields = [];
        const mobileListView = listFormViews.find(({ viewType }) => viewType === "Mobile");
        const desktopListView = listFormViews.find(({ viewType }) => viewType === "Desktop");

        if (isMobileView) {
          if (mobileListView) {
            columnFields = mobileListView.fields;
          } else if (desktopListView) {
            columnFields = desktopListView.fields;
          }
        } else if (desktopListView) {
          columnFields = desktopListView.fields;
        }

        if (!columnFields.length) {
          return data?.document;
        }

        const camelizedListViewFields = columnFields.map((fieldName) => camelize(fieldName));

        tableColumns = tableColumns.map((c) => ({
          ...c,
          value: camelizedListViewFields.includes(c.fieldName),
        }));
      }
      setShownColumns(tableColumns);
      return data?.document;
    }

    return [];
  }, [data]);

  const handleStatusTabChange = (index) => {
    setActiveTabIndex(index);
    onFilterSearch(index);
  };

  const handleStatusSelectChange = (item) => {
    const index = tabOptions.findIndex((o) => o.value === item.value);
    setActiveTabIndex(index);
    onFilterSearch(index);
  };

  const navigationClick = () => {
    navigate(navigationRouteLink);
  };

  const renderTable = () => {
    const areFiltersSelected = Boolean(Object.keys(queryFilters).length);
    if (!(isLoading || isLoadingValues) && values && !values?.data?.length) {
      if (showTableOnly && !showFilters) {
        return null;
      }

      return (
        <div className="table-wrapper">
          {showFilters && Boolean(searchFields?.length) && (
            <TableHeader
              label={startCase(data?.objectName || "")}
              areFiltersSelected={areFiltersSelected}
              fields={searchFields}
              filters={drawerFilters}
              setFilters={setDrawerFilters}
              isFiltersDrawerOpen={isFiltersDrawerOpen}
              setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
              onFilterSearch={onFilterSearch}
              onClearFilters={onClearFilters}
              showFilters={showFilters && Boolean(searchFields?.length)}
              objectName={data?.objectName}
              isChild={data?.relationalObjectType === "Table"}
              queryFilters={queryFilters}
              showExportButton={showExportButton}
            />
          )}
          <NoDataFound
            title={
              areFiltersSelected ?
                "No results found" :
                `No ${startCase(data.objectName)} has been added`
            }
            noDataHideButton={noDataHideButton}
            buttonText={
              areFiltersSelected || isReportUrl ? "" : `Add ${startCase(data?.objectName)}`
            }
            onClick={navigationClick}
            objectName={data?.objectName}
            showButtonWithPermission
          />
        </div>
      );
    }
    return (
      <div className="table-wrapper">
        {showFilters && Boolean(searchFields?.length) && (
          <TableHeader
            label={startCase(data?.objectName || "")}
            areFiltersSelected={areFiltersSelected}
            fields={searchFields}
            filters={drawerFilters}
            setFilters={setDrawerFilters}
            isFiltersDrawerOpen={isFiltersDrawerOpen}
            setIsFiltersDrawerOpen={setIsFiltersDrawerOpen}
            onFilterSearch={onFilterSearch}
            onClearFilters={onClearFilters}
            showFilters={showFilters && Boolean(searchFields?.length)}
            objectName={data?.objectName}
            isChild={data?.relationalObjectType === "Table"}
            queryFilters={queryFilters}
            showExportButton={showExportButton}
          />
        )}
        {!isLoadingValues ? (
          <DynamicObjectOverviewTable
            data={
              values?.data.length > 0 ?
                getDynamicObjectOverviewTableData(
                  values?.data ?? [],
                  data?.document ?? [],
                  objectName,
                  data
                ) :
                skeletonLoadingRows()
            }
            columns={getDynamicObjectOverviewTableColumns(
              listViewFields ?? [],
              data?.objectName,
              data?.actions ?? [],
              shownColumns,
              data,
              showActionCell,
              showCreatedAtCell
            )}
            fields={listViewFields ?? []}
            objectName={objectName}
            perPage={perPage}
            navigationLink={navigationLink}
            perPageOptions={perPageOptions}
            currentPage={currentPage}
            handlePagination={handlePagination}
            totalCount={values?.totalCount}
            totalPages={values?.totalPages}
            sortByColumns={sortByColumns}
            setSortByColumns={setSortByColumns}
            isLoading={isLoading || isLoadingValues}
            showPagination={showPagination}
          />
        ) : (
          <Loader />
        )}
      </div>
    );
  };

  if (!hasViewPermission && !isLoading && permissions) {
    return (
      <BoxedContent className="dynamic-object-overview">
        <Header
          showBreadcrumb={showBreadcrumb}
          leftContent={
            <shownComponents.HeaderLeftContent title={title || startCase(data?.objectName)} />
          }
        />

        <NoDataFound
          title="You do not have access to view this page"
          buttonText="Go Back"
          onClick={() => navigate(-1)}
        />
      </BoxedContent>
    );
  }

  if (showTableOnly) {
    return renderTable();
  }

  return (
    <BoxedContent className="dynamic-object-overview">
      {!isMobileView ? (
        <Header
          showBreadcrumb={showBreadcrumb}
          leftContent={
            <shownComponents.HeaderLeftContent title={title || startCase(data?.objectName)} />
          }
          rightContent={(
            <shownComponents.HeaderRightContent
              objectName={data?.objectName}
              onClickNew={navigationClick}
              shownColumns={shownColumns}
              setShownColumns={setShownColumns}
              showActionButton={showActionButtonState}
            />
          )}
        />
      ) : (
        <RequirePermission parent="Model" scope={data?.objectName} action="Insert">
          <div className="d-grid mb-3">
            <shownComponents.HeaderRightContent
              objectName={data?.objectName}
              onClickNew={navigationClick}
              shownColumns={shownColumns}
              setShownColumns={setShownColumns}
            />
          </div>
        </RequirePermission>
      )}

      <shownComponents.DynamicObjectOverviewBody objectName={data?.objectName} />
      {tabOptions.length > 0 &&
        (!isMobileView800 ? (
          <TabWithStatus
            className="table-tabs-container"
            tabs={tabOptions}
            onClick={handleStatusTabChange}
            activeTabIndex={activeTabIndex}
          />
        ) : (
          <ReactSelect
            id="status"
            onChange={handleStatusSelectChange}
            value={activeTab}
            options={tabOptions}
            placeholder="Select Status"
          />
        ))}

      {renderTable()}
    </BoxedContent>
  );
}

DynamicObjectOverview.propTypes = {
  objectName: PropTypes.string,
  navigationLink: PropTypes.string,
  filters: PropTypes.object,
  showTableOnly: PropTypes.bool,
  showActionCell: PropTypes.bool,
  showPagination: PropTypes.bool,
  showCreatedAtCell: PropTypes.bool,
  showFilters: PropTypes.bool,
  tabs: PropTypes.array,
  components: PropTypes.object,
  noDataHideButton: PropTypes.bool,
  showBreadcrumb: PropTypes.bool,
  showActionButton: PropTypes.bool,
};

DynamicObjectOverview.defaultProps = {
  objectName: "",
  navigationLink: "",
  showTableOnly: false,
  filters: {},
  showActionCell: true,
  showPagination: true,
  showCreatedAtCell: true,
  showFilters: false,
  tabs: [],
  components: defaultComponents,
  showBreadcrumb: true,
  noDataHideButton: false,
  showActionButton: true,
};

export default DynamicObjectOverview;
