import { useRef, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase, startCase } from "lodash";
import { Button } from "@hydra/atom/components";

import { AlertModal } from "@/components/modals";
import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { useModal } from "@/hooks";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { getRentalInvoiceTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";

const formatRentalInvoice = (rentalInvoice) => {
  const data = {
    key: rentalInvoice.id,
    rentalInvoice: {
      label: rentalInvoice.number,
      value: rentalInvoice.id,
    },
    total: formatDecimalValues(rentalInvoice?.total),
    createdAt: rentalInvoice.createdAt,
    isSelected: false,
  };

  if (rentalInvoice.amountOfTax) {
    data.amountOfTax = {
      label: startCase(rentalInvoice.amountOfTax),
      value: rentalInvoice.amountOfTax,
    };
  }

  if (rentalInvoice.salesLocation && !isEmpty(rentalInvoice.salesLocation)) {
    data.salesLocation = {
      label: rentalInvoice.salesLocation?.name,
      value: rentalInvoice.salesLocation?.id,
    };
  }

  if (rentalInvoice.tax && !isEmpty(rentalInvoice.tax)) {
    data.tax = {
      ...rentalInvoice.tax,
      label: rentalInvoice.tax?.name,
      value: rentalInvoice.tax?.id,
    };
  }

  data.rentalDetail = rentalInvoice.rentalDetail
    .map((payment) => ({
      contract: {
        label: payment?.contract?.number,
        value: payment?.contract?.id,
      },
      building: {
        label: payment?.building?.name,
        value: payment?.building?.id,
      },
      unit: payment?.unit.map((unit) => ({
        label: unit?.name,
        value: unit?.id,
      })),
      chequeNo: payment.chequeNo || "",
      paymentDate: new Date(payment.paymentDate),
      rentAmount: payment.rentAmount,
      utilityAmount: payment.utilityAmount,
      securityDepositAmount: payment.securityDepositAmount,
      adminChargesAmount: payment.adminChargesAmount,
      amount: payment.amount,
      paymentMethod: {
        label: startCase(payment.paymentMethod),
        value: payment.paymentMethod,
      },
      tax: {
        label: payment?.tax?.name,
        value: payment?.tax?.id,
        saleRate: payment?.tax?.saleRate,
      },
      taxAmount: payment.taxAmount,
      totalAmount: payment.totalAmount,
      isSelected: true,
      paymentDetailId: payment.paymentDetailId,
    }))
    .sort((a, b) => b.paymentDate - a.paymentDate);

  return data;
};

const calculateTotalAndTax = ({ rentalDetail }) => {
  const data = {
    subtotal: 0,
    taxAmount: 0,
  };

  data.subtotal = rentalDetail.reduce(
    (prevValue, currentValue) => Number(prevValue) + Number(currentValue.amount),
    0
  );

  data.taxAmount = rentalDetail.reduce(
    (prevValue, currentValue) => Number(prevValue) + Number(currentValue.taxAmount),
    0
  );

  data.total = Number(data.subtotal) + Number(data.taxAmount);

  return data;
};

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select invoice
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function CreditNoteForm() {
  const ref = useRef(null);
  const [state, setState] = useState({});
  const [invoiceTableData, setInvoiceTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: invoiceData } = useQuery(
    [kebabCase(dynamicObjectMap.get("RentalInvoiceObjectName")), state?.tenant?.value],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("RentalInvoiceObjectName"), {
        tenant: state?.tenant?.value,
        takePage: 1,
        limitPage: 10,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
      }),
    {
      enabled: Boolean(state?.tenant?.value),
    }
  );

  useEffect(() => {
    if (invoiceData && invoiceData?.data && state?.tenant) {
      const formattedInvoices = invoiceData?.data?.map((i) => formatRentalInvoice(i));
      if (formattedInvoices.length) {
        setInvoiceTableData(formattedInvoices);
      } else {
        setInvoiceTableData([]);
      }
      openModal();
    }
  }, [invoiceData, state?.tenant?.value]);

  const setRentalDetail = (selectedInvoice) => {
    const { tax, salesLocation, amountOfTax, rentalDetail } = selectedInvoice;

    const totalData = calculateTotalAndTax({
      tax,
      amountOfTax,
      rentalDetail,
    });

    ref.current.setFormState({
      tax,
      salesLocation,
      amountOfTax,
      rentalDetail,
      ...totalData,
    });
  };

  const updateRentalDetail = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { amountOfTax, rentalDetail } = formState;

    const updatedRentalDetail = rentalDetail.map((rent) => {
      let { amount } = rent;
      const { tax, taxAmount: prevTaxAmount } = rent;
      let taxAmount = 0;

      if (tax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax,
          taxAmount: ""
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } = calculateTax(parameters);

        amount = principalAmount;
        taxAmount = calculatedTaxAmount;
      }

      return {
        ...rent,
        amount,
        tax,
        taxAmount,
        totalAmount: Number(amount) + Number(taxAmount),
      };
    });

    ref.current.setFormState({
      rentalDetail: updatedRentalDetail,
    });
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { rentalDetail, amountOfTax, tax } = formState;
    const totalData = calculateTotalAndTax({
      tax,
      amountOfTax,
      rentalDetail,
    });

    ref.current.setFormState(totalData);
    updateRentalDetail(key, value);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tenant":
        setState({
          ...state,
          [key]: value,
        });
        break;

      case "rentalDetail":
        setTaxAndTotal(key, value);
        break;

      case "tax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "amountOfTax": {
        if (state[key]) {
          if (state[key]?.value === value?.value) {
            break;
          }
        }

        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));

        setTaxAndTotal(key, value);
        break;
      }

      default:
        break;
    }
  };

  const handleConfirm = () => {
    if (invoiceTableData.length) {
      const selectedInvoice = invoiceTableData.find((r) => r.isSelected);
      setRentalDetail(selectedInvoice);
    }

    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Tenant Payments"
        subtitle="Selected tenant has following pending payments"
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleConfirm}
        size="large"
      >
        {invoiceTableData.length ? (
          <TableWithCheckbox
            data={invoiceTableData}
            setData={setInvoiceTableData}
            columns={getRentalInvoiceTableColumns()}
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("CreditNoteObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(invoiceTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default CreditNoteForm;
