import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Pagination
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { format, subYears } from "date-fns";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getUnitReadyReportRows, getUnitReadyReportColumns } from "./unit-ready-report";
import { getUnitReady } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import { ReportFilterDrawer } from "@/components/drawers";
import appSettings from "@/settings";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportUnitReady({ activeCompany }) {
  const ref = useRef();
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsData, setColumnsData] = useState(
    getUnitReadyReportColumns(ref?.current?.clientWidth)
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState({
    CompanyName: "*",
    SupervisorKey: "*",
    BuildingKey: "*",
    UnitKey: "*",
    StartDate: format(subYears(new Date(), 1), "yyyy-MM-dd"),
    EndDate: format(new Date(), "yyyy-MM-dd"),
  });
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const [isOpen, setIsOpen] = useState(false);

  const formatFilters = (data) => {
    const filterData = {
      CompanyName: "*",
      SupervisorKey: "*",
      BuildingKey: "*",
      UnitKey: "*",
      StartDate: format(subYears(new Date(), 1), "yyyy-MM-dd"),
      EndDate: format(new Date(), "yyyy-MM-dd"),
    };

    if (!data) { return filterData; }

    if (data?.supervisor) {
      filterData.SupervisorKey = data.supervisor.value;
    }

    if (data?.buildingName) {
      filterData.BuildingKey = data?.buildingName?.value;
    }

    if (data?.dateRangeFilter) {
      filterData.StartDate = data?.dateRangeFilter;
    }

    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/unit-ready?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: unitReadyReportData, isLoading } = useQuery(["unit-ready", filters, currentPage, perPage.value], () => getUnitReady({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "WorkOrderNumber",
    ...formatFilters(filters)
  }));

  const totalCount = useMemo(() => unitReadyReportData?.totalCount || 0, [unitReadyReportData]);
  const onClose = (value) => {
    setIsOpen(value);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction?.filter((obj) => obj?.tenantName.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getUnitReadyReportRows({
        data: {
          data: formatReport(unitReadyReportData?.data) || [],
        },
        columns,
      }),
    [unitReadyReportData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer filters={filters} setFilters={setFilters} type="unit-ready" size={400} isOpen={isOpen} onClose={onClose} />
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton />}
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportUnitReady;
