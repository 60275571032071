import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Pagination,
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";

import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getVATExceptionRows, getVATExceptionColumns } from "./vat-exception-statement";
import { getVATExceptions } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import { ReportFilterDrawer } from "@/components/drawers";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportVATException({ exportExcel }) {
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [columnsData, setColumnsData] = useState(getVATExceptionColumns(ref?.current?.clientWidth));
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const { data: vatExceptionData, isLoading } = useQuery(["vat-exception", currentPage, perPage.value], () => getVATExceptions({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "Building"
  }));

  const totalCount = useMemo(() => vatExceptionData?.totalCount ||
  0, [vatExceptionData]);

  const onClose = (value) => {
    setIsOpen(value);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) =>
        obj?.customerName?.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const rows = useMemo(
    () =>
      getVATExceptionRows({
        data: {
          data: formatReport(vatExceptionData?.data) || [],
        },
        columns,
      }),
    [vatExceptionData, columns, filterText]
  );

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-9 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer
            filters={filters}
            setFilters={setFilters}
            size={400}
            isOpen={isOpen}
            onClose={onClose}
          />
          {" "}

        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportVATException;
