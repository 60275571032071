import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import { camelize, pascalize } from "humps";
import { kebabCase, isEqual, cloneDeep } from "lodash";
import { Form, Button } from "@hydra/atom/components";
import {
  prepareDynamicObjectDefaultValues,
  getSelectedFormLayout,
  getFieldColumnClassName,
  checkRules,
  renderField,
  extractStringFromBracketsAndDots,
} from "@/utils/dynamic/helpers";
import { FormLeftHeader } from "@/components/common";
import { setFormValue } from "@/reducers/dynamic/dynamicFormReducer";
import { sectionTypeIcons } from "@/utils/dynamic/constants.js";
import { WidgetSteps } from "@/components/leasing";
import { DisplayField, DynamicPopup } from "@/components/dynamic/fields";
import GoogleAddressAutoComplete from "@/components/common/form/GoogleAddressAutocomplete";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { getDisplayFields } from "./fields/DisplayField";
import { getTestId } from "@/utils/helpers";
import { updateCompanyAccount, getCompanyAccounts } from "@/api/admin/companyAccountApi";
import showToast from "@/utils/toast/helpers";

function DynamicFormButton({ objectName, isLoading, showButtons, isDisabled }) {
  const [searchParams] = useSearchParams();

  const submitButtonTextFromParam = searchParams.get("submitButtonText");

  const submitButtonText = submitButtonTextFromParam ?? "Save";

  return (
    <button
      id={`dynamic-form-submit-button-${kebabCase(objectName)}`}
      type="submit"
      className="btn primary small"
      disabled={isDisabled || isLoading}
      style={{ display: showButtons ? "initial" : "none" }}
      data-testid={getTestId(`${pascalize(objectName)}-Submit-Button`)}
    >
      {isLoading ? <i className="fas fa-circle-notch spin" /> : <span>{submitButtonText}</span>}
    </button>
  );
}

DynamicFormButton.propTypes = {
  objectName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  showButtons: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

DynamicFormButton.defaultProps = {
  isLoading: false,
  showButtons: true,
  isDisabled: false,
};

function StepperButtons({
  objectName,
  handleCancel,
  handleDispatch,
  activeStepIndex,
  steps,
  isLoading,
  showButtons,
  isDisabled,
}) {
  const isFirstStep = activeStepIndex === 0;
  const isLastStep = activeStepIndex === steps.length - 1;
  return (
    <div className="buttons-container buttons-at-end">
      <Button
        small
        bordered
        onClick={handleCancel}
        testId={getTestId(`${pascalize(objectName)}-Cancel-Button`)}
      >
        Cancel
      </Button>
      <button id="dynamic-form-next-button" type="submit" style={{ display: "none" }}>
        Next
      </button>
      {!isFirstStep && (
        <Button
          small
          bordered
          disabled={isLoading}
          onClick={() => handleDispatch("activeStepIndex", activeStepIndex - 1)}
          testId={getTestId(`${pascalize(objectName)}-Prev-Button`)}
        >
          Prev
        </Button>
      )}
      {isLastStep ? (
        <DynamicFormButton
          objectName={objectName}
          isLoading={isLoading}
          showButtons={showButtons}
          isDisabled={isDisabled}
        />
      ) : (
        <Button
          disabled={isDisabled || isLoading}
          small
          type="submit"
          testId={getTestId(`${pascalize(objectName)}-Next-Button`)}
        >
          Next
        </Button>
      )}
    </div>
  );
}

function TabLayoutForm({
  layout,
  state,
  handleDispatch,
  handleCancel,
  objectName,
  isEditing,
  isLoading,
  renderElement,
  renderAdditionalFields,
  onSubmit,
  fields,
  objectData,
  dispatch,
  selectedId,
  onChildStateChange,
  onChildFieldBlur,
  showButtons,
  ...rest
}) {
  const nextStepRef = useRef(null);
  const activeStepIndex = state.activeStepIndex ?? 0;
  const tabElements = layout.elements.map((el) => el);
  const formLayout = tabElements[0].elements[0].elements[0].elements;
  let autoFocusElement = formLayout[0];
  if (autoFocusElement.name) {
    const filteredFieldData = fields.filter(
      (item) => autoFocusElement.name.toLowerCase() === item.name.toLowerCase() && item.disabled
    );
    if (filteredFieldData.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      autoFocusElement = formLayout[1];
    }
  }
  const steps = layout.elements.map((step, index) => ({
    title: step.title,
    isActive: activeStepIndex === index,
    isCompleted: isEditing || activeStepIndex > index,
  }));

  const isLastStep = activeStepIndex === steps.length - 1;

  const handleNextStep = () => {
    if (rest?.onNextSave) {
      const result = rest?.onNextSave(activeStepIndex);
      if (!result) {
        return;
      }
    }
    if (nextStepRef.current !== null) {
      handleDispatch("activeStepIndex", nextStepRef.current);
      nextStepRef.current = null;
      return;
    }

    if (isLastStep) {
      onSubmit();
      return;
    }

    handleDispatch("activeStepIndex", activeStepIndex + 1);
  };

  const handleStepClick = (index) => {
    nextStepRef.current = index;
    const hiddenNextButton = document.getElementById("dynamic-form-next-button");

    if (hiddenNextButton) {
      hiddenNextButton.click();
    }
  };

  return (
    <div className="dynamic-form">
      <WidgetSteps
        steps={steps}
        onClickStep={handleStepClick}
        testId={getTestId(`${pascalize(objectName)}-Tab`)}
      />

      {tabElements.map((tabElement, tabIndex) => (
        <div key={`tab-group-${tabIndex}`} className={tabIndex !== activeStepIndex ? "hidden" : ""}>
          <Form onSubmit={handleNextStep} shouldScrollOnError>
            {tabElement.elements.map((element, index) =>
              renderElement({
                element: { ...element, index },
                fields,
                state,
                objectData,
                dispatch,
                handleDispatch,
                objectName,
                selectedId,
                onChildStateChange,
                onChildFieldBlur,
                autoFocusElement,
              })
            )}
            {tabIndex === 1 && renderAdditionalFields()}
            {tabIndex === activeStepIndex ? (
              <StepperButtons
                objectName={objectName}
                handleCancel={handleCancel}
                handleDispatch={handleDispatch}
                activeStepIndex={activeStepIndex}
                steps={steps}
                isLoading={isLoading}
                showButtons={showButtons}
                isDisabled={
                  (Object.prototype.hasOwnProperty.call(state, "isEmailUnique") &&
                    !state.isEmailUnique) ||
                  (Object.prototype.hasOwnProperty.call(state, "isPhoneUnique") &&
                    !state.isPhoneUnique) ||
                  state.hasErrors ||
                  (Object.prototype.hasOwnProperty.call(state, "isUniqueText") &&
                    !Object.values(state.isUniqueText).every((value) => value))
                }
              />
            ) : null}
          </Form>
        </div>
      ))}
    </div>
  );
}

const renderElement = ({
  element,
  row,
  fields,
  state,
  objectData,
  dispatch,
  handleDispatch,
  objectName,
  selectedId,
  onChildStateChange,
  onChildFieldBlur,
  autoFocusElement,
}) => {
  switch (element.dataType) {
    case "TabElement":
      return (
        <div key={`${element.dataType}-${element.index}`} className={`tab-${element.index}`}>
          {element.elements.map((child) =>
            renderElement({
              element: child,
              fields,
              state,
              objectData,
              dispatch,
              handleDispatch,
              objectName,
              selectedId,
              onChildStateChange,
              onChildFieldBlur,
              autoFocusElement,
            })
          )}
        </div>
      );

    case "SectionElement": {
      // Table element inside tab layout comes as a section with title of Table
      if (element.title === "Table") {
        return (
          <React.Fragment key={`${element.dataType}-${element.index}`}>
            {element.elements.map((child) =>
              renderElement({
                element: child,
                row: element,
                fields,
                state,
                objectData,
                dispatch,
                handleDispatch,
                objectName,
                selectedId,
                onChildStateChange,
                onChildFieldBlur,
                autoFocusElement,
              })
            )}
          </React.Fragment>
        );
      }

      const sectionFieldNames = [];
      element.elements.forEach((e) =>
        e.elements.forEach((item) => sectionFieldNames.push(camelize(item.name)))
      );
      const sectionFields = fields.filter((item) => sectionFieldNames.includes(item.name));
      if (sectionFields.length) {
        const isSectionVisible = sectionFields.some((item) => checkRules(item, state));
        if (!isSectionVisible) {
          return null;
        }
      }

      const isCompanyAccountSection = element.title === "Company Accounts";
      if (isCompanyAccountSection && Array.isArray(state.company) && state.company.length) {
        const companySections = state.company.map((company) => {
          const companySection = {
            dataType: "SectionElement",
            index: element.index,
            title: `${company.label} ${element.title}`,
            subTitle: element.subTitle,
          };

          const sectionElements = cloneDeep(element.elements);
          sectionElements[0].elements = sectionElements[0].elements.map((e) =>
            ({
              ...e,
              company,
            })
          );

          companySection.elements = sectionElements;

          return companySection;
        });

        return companySections.map((sectionElement, index) => (
          <React.Fragment key={`${sectionElement.dataType}-company-${index}`}>
            <div className="row">
              <div className="col-md-3">
                <FormLeftHeader
                  title={sectionElement.title}
                  subtitle={sectionElement.subTitle}
                  icon={sectionTypeIcons(sectionElement.index)}
                />
              </div>
              <div className="col-md-9">
                {sectionElement.elements.map((child) =>
                  renderElement({
                    element: child,
                    row: sectionElement,
                    fields,
                    state,
                    objectData,
                    dispatch,
                    handleDispatch,
                    objectName,
                    selectedId,
                    onChildStateChange,
                    onChildFieldBlur,
                    autoFocusElement,
                  })
                )}
              </div>
            </div>
            <hr className="full-hr" />
          </React.Fragment>
        ));
      }

      if (isCompanyAccountSection && (!state.company || !state.company.length)) {
        return null;
      }

      return (
        <React.Fragment key={`${element.dataType}-${element.index}`}>
          <div className="row">
            <div className="col-md-3">
              <FormLeftHeader
                title={element.title}
                subtitle={element.subTitle}
                icon={sectionTypeIcons(element.index)}
              />
            </div>
            <div className="col-md-9">
              {element.elements.map((child) =>
                renderElement({
                  element: child,
                  row: element,
                  fields,
                  state,
                  objectData,
                  dispatch,
                  handleDispatch,
                  objectName,
                  selectedId,
                  onChildStateChange,
                  onChildFieldBlur,
                  autoFocusElement,
                })
              )}
            </div>
          </div>
          <hr className="full-hr" />
        </React.Fragment>
      );
    }

    case "RowElement":
      return (
        <div className="row">
          {element.elements.map((child) =>
            renderElement({
              element: child,
              row: element,
              fields,
              state,
              objectData,
              dispatch,
              handleDispatch,
              objectName,
              selectedId,
              onChildStateChange,
              onChildFieldBlur,
              autoFocusElement,
            })
          )}
        </div>
      );

    case "FieldElement": {
      const field = fields.find((f) => f.name === camelize(element.name));
      if (!field && element.name && element.isDisplayField) {
        const { lookupFieldName } = getDisplayFields(element.name);
        if (lookupFieldName) {
          const parentField = fields.find((f) => f.name === camelize(lookupFieldName));
          if (!checkRules(parentField, state)) {
            return null;
          }
        }

        return (
          <div
            key={`${element.dataType}-${element.name}`}
            className={`col-sm-12 col-md-${element.columns}`}
          >
            <DisplayField
              state={state}
              field={element}
              name={element.name}
              disabled
              testId={getTestId(`${pascalize(objectName)}-${pascalize(element?.name)}-Display`)}
            />
          </div>
        );
      }

      // Rendering group of address fields
      if (!field && element?.name) {
        const groupFields = fields.filter((f) => f.groupName === element.name);
        if (groupFields.length) {
          return (
            <GoogleAddressAutoComplete
              key={groupFields[0].groupName}
              state={state}
              dispatch={dispatch}
              groupName={groupFields[0].groupName}
              testId={getTestId(`${pascalize(objectName)}-${pascalize(element?.name)}-Address`)}
            />
          );
        }

        return null;
      }

      if (!checkRules(field, state)) {
        return null;
      }

      return (
        <div
          key={`${element.dataType}-${element.name}`}
          className={getFieldColumnClassName({
            field,
            fieldLayout: element,
            row,
          })}
        >
          {renderField({
            field,
            parentObjectName: objectName,
            state,
            onChange: handleDispatch,
            parentId: selectedId,
            onChildStateChange,
            onChildFieldBlur,
            objectData,
            autoFocusElement,
            company: element.company,
          })}
        </div>
      );
    }

    case "TableElement":
      return <div key={`${element.dataType}-${element.name}`} id={camelize(element.title)} />;

    case "ButtonElement": {
      const { left: buttonType, right: actionName } = extractStringFromBracketsAndDots(
        element.name
      );
      const actionField = objectData.actions.find((item) => item.name === actionName);
      const modalName = extractStringFromBracketsAndDots(actionField.modal);
      const layoutForm = objectData.layout.forms.find(
        (item) => item.name === state[camelize(modalName)]?.label
      );

      const renderName = () => {
        if (buttonType === "Action" && actionField) {
          return actionField.label;
        }

        return element.name;
      };

      const onClick = () => {
        if (!layoutForm) {
          handleDispatch("isDynamicPopupModalHasError", "Please select an option first");
          return;
        }

        if (
          pascalize(objectName) === dynamicObjectMap.get("TenantObjectName") &&
          actionName === "UploadDocument"
        ) {
          const stateKey = "documentTable";
          handleDispatch(stateKey, {
            [camelize(modalName)]: state[camelize(modalName)],
          });
        }

        handleDispatch("isDynamicPopupModalHasError", "");
        handleDispatch("dynamicPopupLayout", layoutForm);
        handleDispatch("isDynamicPopupModalOpen", true);
      };

      return (
        <>
          <div className={`col-md-${element.columns} d-flex align-items-end`}>
            <Button
              onClick={onClick}
              testId={getTestId(`${pascalize(objectName)}-${pascalize(element.name)}-Button`)}
            >
              {renderName()}
            </Button>
          </div>
          <div className="col-md-12">
            {state.isDynamicPopupModalHasError && !state[camelize(modalName)] && (
              <span className="error-text">{state.isDynamicPopupModalHasError}</span>
            )}
          </div>
        </>
      );
    }

    default:
      return <div key={`${element.dataType}-${element.name}`}>{element.title}</div>;
  }
};

const renderForm = ({ selectedFormLayout, ...rest }) => {
  const formLayout = selectedFormLayout.elements[0].elements[0].elements;
  const { fields: fieldsData } = rest;
  let autoFocusElement = formLayout[0];
  if (autoFocusElement.name) {
    const filteredFieldData = fieldsData.filter(
      (item) => autoFocusElement.name.toLowerCase() === item.name.toLowerCase() && item.disabled
    );
    if (filteredFieldData.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      autoFocusElement = formLayout[1];
    }
  }
  return selectedFormLayout.elements.map((element, index) =>
    renderElement({
      element: { ...element, index },
      autoFocusElement,
      ...rest,
    })
  );
};

// TODO: prefill company accounts if one account is selected
// const prefillCompanyAccounts = async (key, value, state, dispatch) => {
//   if (!value) return;

//   const { company } = state;

//   if (company && company.length < 2) {
//     return;
//   }

//   const { companyId, companyName, label } = value;
//   const camelizeCompanyName = camelize(companyName);
//   const fieldName = key.split(camelizeCompanyName)[1];
//   const companies = company.filter((c) => c.value !== companyId);

//   const response = await Promise.all(
//     companies.map((c) => getLedgerAccounts({ companyId: c.value, name: label }))
//   );

//   const companyAccounts = {};

//   companies.forEach((c, index) => {
//     const { label: companyLabel } = c;
//     const camelCaseCompanyName = camelize(companyLabel);
//     const stateKey = `${camelCaseCompanyName}${fieldName}`;
//     const resp = response[index];
//     if (resp.data.length) {
//       const account = resp.data[0];
//       companyAccounts[stateKey] = {
//         companyAccount: true,
//         companyName: companyLabel,
//         companyId: c.value,
//         label: account.name,
//         value: account.id,
//       };
//     } else {
//       companyAccounts[stateKey] = null;
//     }
//   });

//   dispatch(setState(companyAccounts));
// };

const updateLinkedCompanyAccount = async (key, value, state, recordId) => {
  if (!value) return;

  const { company } = state;

  if (company && company.length < 2) {
    return;
  }

  const { companyName, companyId, value: accountId } = value;
  const camelizeCompanyName = camelize(companyName);
  const fieldName = key.split(camelizeCompanyName)[1];

  const filters = {
    companyId,
    recordId,
    accountType: fieldName,
    isLinkedWithRecord: true,
  };

  try {
    const response = await getCompanyAccounts(filters);

    if (response?.data && response.data?.length) {
      const companyAccountId = response?.data[0]?.id;

      await updateCompanyAccount(companyAccountId, {
        accountId,
        companyId,
        isLinkedWithRecord: true,
        accountType: fieldName,
        additionalProperties: {
          recordId
        }
      });
    }

  } catch (error) {
    showToast(`Could not update company account for ${fieldName}. Try again!`, "error");
  }
};

function DynamicForm({
  state,
  dispatch,
  handleSubmit,
  renderAdditionalFields,
  objectData,
  isEditing,
  selectedId,
  objectName,
  disabled,
  hiddenFields,
  readOnlyFields,
  requiredFields,
  showButtons,
  handleCancel,
  isLoading,
  onStateChange,
  onChildStateChange,
  onChildFieldBlur,
  layout,
  ...rest
}) {
  const [removeFields, setRemoveFields] = useState([]);

  const fields =
    objectData?.document.filter((field) => {
      if (field.hidden) {
        return false;
      }

      if (hiddenFields.includes(field.name)) {
        return false;
      }

      if (disabled || readOnlyFields.includes(field.name)) {
        field.readOnly = true;
      }

      if (requiredFields.includes(field.name)) {
        field.required = true;
      }

      return true;
    }) ?? [];

  const newRemoveFields = fields.filter((field) => !checkRules(field, state));
  const processRemoveFields = useCallback(() => {
    if (isEqual(removeFields, newRemoveFields)) {
      return;
    }
    const defaultValues = prepareDynamicObjectDefaultValues(removeFields);
    Object.entries(defaultValues).forEach(([key, value]) => {
      dispatch(setFormValue(key, value));
    });

    newRemoveFields.forEach((item) => {
      if (!(item.objectFieldType === "Lookup" && item.lookupObjectName === "LedgerAccount")) {
        dispatch(setFormValue(item.camelizedName, undefined));
      }
    });

    setRemoveFields(newRemoveFields);
  }, [newRemoveFields]);

  useEffect(() => {
    // TODO: Needs more testing, it is setting unexpected field values to undefined
    // processRemoveFields();
  }, [processRemoveFields]);

  const { layout: selectedFormLayout, isTabLayout } = getSelectedFormLayout({
    forms: objectData?.layout?.forms,
    isEditing,
    selectedLayout: layout,
  });

  const handleDispatch = (key, value) => {
    dispatch(setFormValue(key, value));
    if (key === "currentStatus") {
      dispatch(setFormValue("inactiveReason", null));
    }
    if (!isEditing && value?.isCompanyAccount) {
      // TODO: prefill company accounts
      // prefillCompanyAccounts(key, value, state, dispatch);
    }
    if (isEditing && value?.isCompanyAccount) {
      updateLinkedCompanyAccount(key, value, state, selectedId);
    }
    onStateChange(key, value);
  };

  const renderFields = () => {
    const visibleFields = fields.filter(
      (f) => !["checkbox", "table", "attachment"].includes(f.objectFieldType.toLowerCase())
    );

    const checkboxFields = fields.filter((f) =>
      ["checkbox"].includes(f.objectFieldType.toLowerCase())
    );

    const attachmentFields = fields.filter((f) =>
      ["attachment"].includes(f.objectFieldType.toLowerCase())
    );

    return [
      visibleFields.map((f, index) => (
        <div key={`field-${index}`} className={getFieldColumnClassName({ field: f })}>
          {renderField({
            field: f,
            parentObjectName: objectName,
            state,
            onChange: handleDispatch,
            parentId: selectedId,
          })}
        </div>
      )),
      checkboxFields.map((f, index) => (
        <div key={`c-field-${index}`} className={getFieldColumnClassName({ field: f })}>
          {renderField({
            field: f,
            parentObjectName: objectName,
            state,
            onChange: handleDispatch,
            parentId: selectedId,
          })}
        </div>
      )),
      attachmentFields.map((f, index) => (
        <div key={`a-field-${index}`} className={getFieldColumnClassName({ field: f })}>
          {renderField({
            field: f,
            parentObjectName: objectName,
            state,
            onChange: handleDispatch,
            parentId: selectedId,
          })}
        </div>
      )),
    ];
  };

  const renderRelationalFields = () => {
    const visibleFields = fields.filter((f) => ["table"].includes(f.objectFieldType.toLowerCase()));

    if (selectedFormLayout && isTabLayout) {
      let tableElementLayouts = [];

      // Find table field layout inside tab layout
      selectedFormLayout.elements.forEach((tab) => {
        const tabTableElements = tab.elements
          .filter((e) => e.title === "Table")
          .map((t) => t.elements[0]);
        tableElementLayouts = tableElementLayouts.concat(tabTableElements);
      });

      return visibleFields.map((f) => {
        const tableLayout = tableElementLayouts.find(
          (tableElement) => camelize(tableElement.title) === f.name
        );

        if (document.getElementById(f.name)) {
          return createPortal(
            renderField({
              field: f,
              parentObjectName: objectName,
              state,
              onChange: handleDispatch,
              parentId: selectedId,
              fieldLayout: tableLayout,
              onChildStateChange,
              onChildFieldBlur,
            }),
            document.getElementById(f.name)
          );
        }

        return null;
      });
    }

    if (selectedFormLayout) {
      return visibleFields.map((f) => {
        // Find table field layout from selected layout
        const fieldLayout = selectedFormLayout.elements.find((e) => camelize(e.title) === f.name);

        if (document.getElementById(f.name)) {
          return createPortal(
            renderField({
              field: f,
              parentObjectName: objectName,
              state,
              onChange: handleDispatch,
              parentId: selectedId,
              fieldLayout,
              onChildStateChange,
              onChildFieldBlur,
            }),
            document.getElementById(f.name)
          );
        }

        return null;
      });
    }

    return visibleFields.map((f) =>
      renderField({
        field: f,
        parentObjectName: objectName,
        state,
        onChange: handleDispatch,
        parentId: selectedId,
        onChildStateChange,
        onChildFieldBlur,
      })
    );
  };

  const onSubmit = () => {
    if (
      (Object.prototype.hasOwnProperty.call(state, "isEmailUnique") && !state.isEmailUnique) ||
      (Object.prototype.hasOwnProperty.call(state, "isPhoneUnique") && !state.isPhoneUnique) ||
      state.hasErrors ||
      (Object.prototype.hasOwnProperty.call(state, "isUniqueText") &&
        !Object.values(state.isUniqueText).every((value) => value))
    ) {
      return;
    }

    if (rest?.onNextSave) {
      const result = rest?.onNextSave();
      if (!result) {
        return;
      }
    }

    handleSubmit();
  };

  const closeDynamicPopup = () => {
    handleDispatch("isDynamicPopupModalHasError", "");
    handleDispatch("isDynamicPopupModalOpen", false);
    handleDispatch("dynamicPopupLayout", null);
  };

  const resetDynamicPopup = () => {
    closeDynamicPopup();

    if (pascalize(objectName) === dynamicObjectMap.get("TenantObjectName")) {
      const stateKey = "documentTable";
      handleDispatch(stateKey, null);
    }
  };

  if (!selectedFormLayout) {
    return (
      <div className="dynamic-form">
        <Form onSubmit={onSubmit} shouldScrollOnError>
          <div className="row">{[...renderFields()]}</div>
          <DynamicFormButton
            objectName={objectName}
            isLoading={isLoading}
            showButtons={showButtons}
          />
        </Form>
        {renderRelationalFields()}
      </div>
    );
  }
  if (isTabLayout && selectedFormLayout) {
    return (
      <>
        <DynamicPopup
          isOpen={state.isDynamicPopupModalOpen}
          onCancel={resetDynamicPopup}
          onSave={closeDynamicPopup}
          objectName={objectName}
          layout={state.dynamicPopupLayout}
          state={state}
          handleDispatch={handleDispatch}
          selectedId={selectedId}
          onChildStateChange={onChildStateChange}
          onChildFieldBlur={onChildFieldBlur}
          fields={fields}
          objectData={objectData}
          dispatch={dispatch}
        />
        <TabLayoutForm
          layout={selectedFormLayout}
          state={state}
          handleDispatch={handleDispatch}
          handleCancel={handleCancel}
          objectName={objectName}
          isEditing={isEditing}
          isLoading={isLoading}
          renderElement={renderElement}
          onSubmit={onSubmit}
          renderAdditionalFields={renderAdditionalFields}
          showButtons={showButtons}
          fields={fields}
          objectData={objectData}
          dispatch={dispatch}
          selectedId={selectedId}
          onChildStateChange={onChildStateChange}
          onChildFieldBlur={onChildFieldBlur}
          {...rest}
        />
        {renderRelationalFields()}
      </>
    );
  }
  return (
    <div className="dynamic-form">
      <DynamicPopup
        isOpen={state.isDynamicPopupModalOpen}
        onCancel={resetDynamicPopup}
        onSave={closeDynamicPopup}
        objectName={objectName}
        layout={state.dynamicPopupLayout}
        state={state}
        handleDispatch={handleDispatch}
        selectedId={selectedId}
        onChildStateChange={onChildStateChange}
        onChildFieldBlur={onChildFieldBlur}
        fields={fields}
        objectData={objectData}
        dispatch={dispatch}
      />
      <Form onSubmit={onSubmit} shouldScrollOnError>
        {selectedFormLayout &&
          renderForm({
            selectedFormLayout,
            fields,
            state,
            objectData,
            dispatch,
            handleDispatch,
            objectName,
            selectedId,
            onChildStateChange,
            onChildFieldBlur,
          })}
        {renderAdditionalFields()}
        {showButtons ? (
          <div className="col">
            <div className="buttons-at-end">
              <Button
                disabled={isLoading}
                bordered
                small
                onClick={handleCancel}
                testId={getTestId(`${pascalize(objectName)}-Cancel-Button`)}
              >
                Cancel
              </Button>
              <DynamicFormButton
                objectName={objectName}
                isLoading={isLoading}
                isDisabled={
                  (Object.prototype.hasOwnProperty.call(state, "isEmailUnique") &&
                    !state.isEmailUnique) ||
                  (Object.prototype.hasOwnProperty.call(state, "isPhoneUnique") &&
                    !state.isPhoneUnique) ||
                  state.hasErrors ||
                  (Object.prototype.hasOwnProperty.call(state, "isUniqueText") &&
                    !Object.values(state.isUniqueText).every((value) => value))
                }
              />
            </div>
          </div>
        ) : (
          <DynamicFormButton objectName={objectName} isLoading={isLoading} showButtons={false} />
        )}
      </Form>
      {renderRelationalFields()}
    </div>
  );
}

DynamicForm.propTypes = {
  objectName: PropTypes.string.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  objectData: PropTypes.object,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  isEditing: PropTypes.bool,
  disabled: PropTypes.bool,
  hiddenFields: PropTypes.array,
  readOnlyFields: PropTypes.array,
  requiredFields: PropTypes.array,
  showButtons: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleCancel: PropTypes.func,
  onStateChange: PropTypes.func,
  renderAdditionalFields: PropTypes.func,
  onChildStateChange: PropTypes.func,
  onChildFieldBlur: PropTypes.func,
  layout: PropTypes.string,
};

DynamicForm.defaultProps = {
  isEditing: false,
  selectedId: null,
  objectData: {},
  disabled: false,
  hiddenFields: [],
  readOnlyFields: [],
  requiredFields: [],
  showButtons: true,
  isLoading: false,
  handleCancel: () => {},
  onStateChange: () => {},
  renderAdditionalFields: () => {},
  onChildStateChange: () => {},
  onChildFieldBlur: () => {},
  layout: "",
};

export { DynamicForm, renderForm, renderElement };
