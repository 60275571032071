import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button,
  FormControl
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { subYears } from "date-fns";
import {
  SvgIcon, CustomDropdown, IconButton,
  CustomizedDateRangePicker
} from "@/components/common";
import { getUtilityContractRows, getUtilityContractColumns } from "./utility-contract";
import { getUtilityContractReport } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import { ReportFilterDrawer } from "@/components/drawers";
import appSettings from "@/settings";
import { formatApiPayloadDate } from "@/utils/helpers";

function ReportUtilityContract({ activeCompany }) {
  const ref = useRef();
  const [columnsData, setColumnsData] = useState(
    getUtilityContractColumns(ref?.current?.clientWidth)
  );
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState({
    TenantName: "*",
    ContractNumber: "*",
    PaymentType: "*"
  });
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([subYears(new Date(), 1), new Date()]);

  const formatFilters = (data) => {
    const filterData = {
      TenantName: "*",
      ContractNumber: "*",
      StartDate: formatApiPayloadDate(dateRange[0]),
      EndDate: formatApiPayloadDate(dateRange[1]),
    };

    if (!data) { return filterData; }

    if (data?.tenantName) {
      filterData.TenantName = data?.tenantName?.label;
    }

    if (data?.tenantType) {
      filterData.TenantType = data?.tenantType?.value;
    }

    if (data?.company) {
      filterData.CompanyName = data?.company?.name;
    }
    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/utility-contract?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: utilityContractReportData, isLoading } = useQuery(["utility-contract-report", filters, dateRange], () => getUtilityContractReport({
    ...formatFilters(filters),
  }
  ));

  const onClose = (value) => {
    setIsOpen(value);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction?.filter((obj) => obj?.customerName?.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getUtilityContractRows({
        data: {
          data: formatReport(utilityContractReportData?.data) || [],
        },
        columns,
      }),
    [utilityContractReportData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer filters={filters} setFilters={setFilters} type="utility-contract" size={400} isOpen={isOpen} onClose={onClose} />
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton />}
      </div>
    </div>
  );
}

export default ReportUtilityContract;
