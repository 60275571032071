import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Pagination,
  FormControl
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import {
  SvgIcon, CustomDropdown, IconButton,
  CustomizedDateRangePicker
} from "@/components/common";
import { getBuildingCostRows, getBuildingCostColumns } from "./building-cost-statement";
import { getBuildingCost } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import { ReportFilterDrawer } from "@/components/drawers";
import appSettings from "@/settings";
import { formatApiPayloadDate } from "@/utils/helpers";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportBuildingCost({ activeCompany }) {
  const ref = useRef();
  const [columnsData, setColumnsData] = useState(
    getBuildingCostColumns(ref?.current?.clientWidth)
  );
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState({
    // TenantName: "*",
    // CompanyName: "*",
    // TenantType: "*",
    // ComponentNumber: "*",
    // PaymentMethod: "*",
    // PaymentStatus: "*",
    // PaymentType: "*",
  });
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };

  const formatFilters = (data) => {
    const filterData = {
      // TenantName: "*",
      // CompanyName: "*",
      // ComponentNumber: "*",
      // PaymentMethod: "*",
      // PaymentStatus: "*",
      // Companyid: "epico"
    };

    if (!data) { return filterData; }

    if (data?.tenant) {
      filterData.TenantName = data.tenant.name;
    }

    if (data?.componentName?.length > 0) {
      filterData.ComponentName = data.componentName;
    }

    if (data?.paymentMethod) {
      filterData.PaymentMethod = data?.paymentMethod?.value;
    }
    if (data?.paymentStatus) {
      filterData.PaymentStatus = data?.paymentStatus?.value;
    }

    if (data?.company) {
      filterData.CompanyName = data?.company?.name;
    }

    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/building-cost?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}&StartDate=${formatApiPayloadDate(dateRange[0])}&EndDate=${formatApiPayloadDate(dateRange[1])}`, "_blank", "noopener,noreferrer");

  const { data: bussinessCostData, isLoading } = useQuery(["business-cost-report", currentPage, perPage.value, filters, dateRange], () => getBuildingCost({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "Number",
    StartDate: formatApiPayloadDate(dateRange[0]),
    EndDate: formatApiPayloadDate(dateRange[1]),
    ...formatFilters(filters)
  }));
  const totalCount = useMemo(() => bussinessCostData?.totalCount || 0, [bussinessCostData]);

  const onClose = (value) => {
    setIsOpen(value);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction?.filter((obj) => obj?.customerName.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getBuildingCostRows({
        data: {
          data: formatReport(bussinessCostData?.data) || [],
        },
        columns,
      }),
    [bussinessCostData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-3">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton />}
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportBuildingCost;
