import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input,
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getForthcomingVacancyRows, getForthcomingVacancyColumns } from "./forthcoming-vacancy";
import { getForthcomingVacancy } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";

function ReportForthcomingVacancy({ activeCompany }) {
  const ref = useRef();
  const [columnsData, setColumnsData] = useState(
    getForthcomingVacancyColumns(ref?.current?.clientWidth)
  );
  const [filterText, setFilterText] = useState("");
  const [filters, setFilters] = useState({
    CompanyName: "*"
  });

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const formatFilters = (data) => {
    const filterData = {
      CompanyName: "*"
    };

    if (!data) { return filterData; }

    if (data?.tenantName?.length > 0) {
      filterData.TenantName = data.tenantName;
    }

    if (data?.tenantType) {
      filterData.TenantType = data?.tenantType?.value;
    }

    if (data?.company) {
      filterData.CompanyName = data?.company?.name;
    }

    return filterData;
  };

  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/forthcoming-vacancy?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: forthcomingVacancyData, isLoading } = useQuery(["forthcoming-vacancy", filters], () => getForthcomingVacancy(formatFilters(filters)));

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction?.filter((obj) => obj?.tenantName.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getForthcomingVacancyRows({
        data: {
          data: formatReport(forthcomingVacancyData?.data) || [],
        },
        columns,
      }),
    [forthcomingVacancyData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton />}
      </div>
    </div>
  );
}

export default ReportForthcomingVacancy;
