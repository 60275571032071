import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Pagination,
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getForthComingRows, getForthComingColumns } from "./forth-coming-renewal-statement";
import { getForthComingRenewals } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportForthComingRenewal({ exportExcel }) {
  const [screenWidth] = useState(window.innerWidth);
  const ref = useRef();
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [columnsData, setColumnsData] = useState(
    getForthComingColumns(screenWidth)
  );

  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };
  const [filterText, setFilterText] = useState("");
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const { data: forthComingRenewalData, isLoading } = useQuery(["forth-coming-renewal", currentPage, perPage.value], () => getForthComingRenewals({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "Company"
  }));

  const totalCount = useMemo(() => forthComingRenewalData?.totalCount ||
  0, [forthComingRenewalData]);

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction
        ?.filter((obj) => obj?.contractNumber?.toLowerCase().includes(filterText.toLowerCase()))
        ?.map((obj, index) => ({ ...obj, sn: index + 1 + (currentPage - 1) * perPage.value }));
    }

    return transaction?.map((obj, index) =>
      ({ ...obj, sn: index + 1 + (currentPage - 1) * perPage.value }));
  };

  const rows = useMemo(
    () =>
      getForthComingRows({
        data: {
          data: formatReport(forthComingRenewalData?.data) || [],
        },
        columns,
      }),
    [forthComingRenewalData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by contract number"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton />}
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportForthComingRenewal;
