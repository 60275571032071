import { useState, useRef, useMemo } from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import {
  getAgingRows, getSupplierStatementRows, getAdvancePaymentRows,
  getSupplierStatementColumns,
  getAgingColumns, getAdvancePaymentColumns
} from "./supplier-statement";
import DynamicObjectDetails from "@/pages/dynamic/detail-views/DynamicObjectDetails";
import { getSupplierOpenTransaction, getSupplierAging, getAdvancePayment } from "@/api/finance/reportApi";

function ReportSupplierStatement({ id }) {
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const [filterOpenTransactionText, setFilterOpenTransactionText] = useState("");
  const [filterPaymentText, setFilterPaymentText] = useState("");
  const [agingColumnsData, setAgingColumnsData] = useState(
    getAgingColumns(ref?.current?.clientWidth)
  );

  const [paymentColumnsData, setPaymentColumnsData] = useState(
    getAdvancePaymentColumns(ref?.current?.clientWidth)
  );
  const { data: openTransactionData } = useQuery(["open-transaction", id], () => getSupplierOpenTransaction({ SupplierId: id }));
  const { data: agingData } = useQuery(["supplier-aging", id], () => getSupplierAging({ SupplierId: id }));
  const { data: paymentData } = useQuery(["advance-payment", id], () => getAdvancePayment({ SupplierId: id }));

  const agingColumns = useMemo(() => agingColumnsData.filter((c) => c.value), [agingColumnsData]);
  const paymentColumns = useMemo(() => paymentColumnsData.filter((c) => c.value), [paymentColumnsData]);

  const columns = useMemo(
    () => getSupplierStatementColumns(ref?.current?.clientWidth),
    [ref?.current?.clientWidth]
  );

  const formatOpenTransactionReport = (transaction) => {
    if (filterOpenTransactionText) {
      return transaction.filter((obj) => obj.type.toLowerCase().includes(filterOpenTransactionText.toLowerCase()));
    }
    return transaction;
  };

  const rows = useMemo(
    () =>
      getSupplierStatementRows({
        data: {
          data: formatOpenTransactionReport(openTransactionData?.data || []),
        },
        columns,
      }),
    [openTransactionData?.data, columns, filterOpenTransactionText]
  );

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) => obj.type.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const agingRows = useMemo(
    () =>
      getAgingRows({
        data: {
          data: formatReport(agingData?.data || []),
        },
        columns: agingColumns,
      }),
    [agingData?.data, agingColumns, filterText]
  );

  const advancePaymentRows = useMemo(
    () =>
      getAdvancePaymentRows({
        data: {
          data: formatReport(paymentData?.data || []),
        },
        columns: paymentColumns,
      }),
    [paymentData?.data, paymentColumns, filterText]
  );

  const handleColumnResize = (ci, width) => {
    setAgingColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
    setPaymentColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  return (
    <>
      <DynamicObjectDetails objectName="Supplier" id={id} showHeader={false} showTransfers={false} />
      <div className="table-wrapper report-sheet" ref={ref}>
        <div className="row filter-row">
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={filterPaymentText}
              onChange={setFilterPaymentText}
              placeholder="Search by name"
            />
          </div>
        </div>
        <div className="react-grid-container">
          <h6><b>Advance Payments</b></h6>
          <ReactGrid
            key={filterOpenTransactionText}
            rows={advancePaymentRows}
            columns={paymentColumns}
            stickyTopRows={1}
            enableFillHandle
            enableRangeSelection
            disableVirtualScrolling
          />
        </div>
      </div>
      <div className="table-wrapper report-sheet" ref={ref}>
        <div className="row filter-row">
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={filterOpenTransactionText}
              onChange={setFilterOpenTransactionText}
              placeholder="Search by name"
            />
          </div>
        </div>
        <div className="react-grid-container">
          <h6><b>Open Transaction</b></h6>
          <ReactGrid
            key={filterOpenTransactionText}
            rows={rows}
            columns={columns}
            stickyTopRows={1}
            enableFillHandle
            enableRangeSelection
            disableVirtualScrolling
          />
        </div>
      </div>
      <div className="table-wrapper report-sheet" ref={ref}>
        <div className="row filter-row">
          <div className="col-md-3">
            <Input
              className="input-height-fix"
              value={filterText}
              onChange={setFilterText}
              placeholder="Search by name"
            />
          </div>
        </div>
        <div className="react-grid-container">
          <h6><b>Aging</b></h6>
          <ReactGrid
            key={filterText}
            rows={agingRows}
            columns={agingColumns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        </div>
      </div>
    </>
  );
}

export default ReportSupplierStatement;
