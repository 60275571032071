import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input, Pagination } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { SvgIcon, CustomDropdown, IconButton } from "@/components/common";
import { getPettyCashBySupervisorColumns, getPettyCashBySupervisorRows } from "./petty-cash-supervisor-statement";
import { getPettyCashBySupervisor } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportPettyCashBySupervisor({ id, activeCompany }) {
  const ref = useRef();
  // eslint-disable-next-line no-unused-vars
  const [columnsData, setColumnsData] = useState(
    getPettyCashBySupervisorColumns(ref?.current?.clientWidth)
  );
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters] = useState({
    SupervisorId: id,
    BuildingId: "*"
  });
  const [filterText, setFilterText] = useState("");

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };
  const formatFilters = (data) => {
    const filterData = {
      SupervisorId: id,
      BuildingId: "*"
    };

    if (data?.building) {
      filterData.BuildingId = data?.building?.id;
    }

    if (data?.supervisor) {
      filterData.SupervisorId = data?.supervisor?.id;
    }

    return filterData;
  };
  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/petty-cash-claim-by-supervisor?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: pettyCashData, isLoading } = useQuery(["petty-cash-claim-by-supervisor", currentPage, perPage.value, filters], () => getPettyCashBySupervisor({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "BuildingName",
    ...formatFilters(filters)
  }));
  const totalCount = useMemo(() => pettyCashData?.totalCount || 0, [pettyCashData]);

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) => obj.buildingName.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getPettyCashBySupervisorRows({
        data: {
          data: formatReport(pettyCashData?.data) || [],
        },
        columns,
      }),
    [pettyCashData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          marginPagesDisplayed={3}
          pageRangeDisplayed={0}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportPettyCashBySupervisor;
