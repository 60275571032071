import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { startOfYear } from "date-fns";
import { formatApiPayloadDate } from "@/utils/helpers";
import {
  SvgIcon, CustomDropdown, IconButton
} from "@/components/common";
import { getBuildingRevenueRows, getBuildingRevenueColumns } from "./building-revenue-statement";
import { getBuildingRevenue } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";
import { mergeDataByMonth } from "@/components/finance/reporting/helpers";

function ReportBuildingRevenue({ activeCompany }) {
  const ref = useRef();
  const [filters,] = useState({});
  const [dateRange,] = useState([startOfYear(new Date()), new Date()]);
  const [filterText, setFilterText] = useState("");
  const [columnsData, setColumnsData] = useState(
    getBuildingRevenueColumns()
  );
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const formatFilters = () => {
    const filterData = {
      StartDate: formatApiPayloadDate(dateRange[0]),
      EndDate: formatApiPayloadDate(dateRange[1]),
    };

    return filterData;
  };
  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/building-revenue?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters(filters))}`, "_blank", "noopener,noreferrer");

  const { data: buildingRevenueData, isLoading } = useQuery(["building-revenue", filters], () => getBuildingRevenue({
    ...formatFilters(filters)
  }));

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) => obj?.buildingName?.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getBuildingRevenueRows({
        data: {
          data: formatReport(mergeDataByMonth(buildingRevenueData?.data)) || [],
        },
        columns,
      }),
    [buildingRevenueData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <CustomDropdown data={columnsData} setColumnData={setColumnsData} />
        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
      </div>
    </div>
  );
}

export default ReportBuildingRevenue;
