import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Loader,
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { SvgIcon, IconButton } from "@/components/common";
import { getTaxLiabilityRows, getTaxLiabilityColumns } from "./tax-liability-statement";
import { ReportFilterDrawer } from "@/components/drawers";
import { getTaxLiability } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";

function ReportTaxLiability({ exportExcel, accountingPeriod: accountingPeriodData }) {
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [accountingPeriod, setAccountingPeriod] = useState(null);
  const [columnsData, setColumnsData] = useState(
    getTaxLiabilityColumns(ref?.current?.clientWidth)
  );
  const [filters, setFilters] = useState({});
  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);

  const onClose = (value) => {
    setIsOpen(value);
  };

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) => obj.name.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  useEffect(() => {
    setAccountingPeriod(accountingPeriodData);
  }, [accountingPeriodData]);

  const { data: taxLiabilityData, isLoading } = useQuery(["tax-liability"], getTaxLiability);

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getTaxLiabilityRows({
        data: {
          data: formatReport(taxLiabilityData?.data) || [],
        },
        columns,
      }),
    [taxLiabilityData, columns]
  );

  if (taxLiabilityData && accountingPeriod) {
    <Loader />;
  }

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-6 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer
            filters={filters}
            setFilters={setFilters}
            size={400}
            isOpen={isOpen}
            onClose={onClose}
          />
        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
      </div>
    </div>
  );
}

export default ReportTaxLiability;
