import { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { pascalize } from "humps";
import { getTestId, formatDate } from "@/utils/helpers";
import { handleDrawer } from "@/utils/modal/helpers";

function CheckboxCell({
  value,
  row: { index },
  column: { id },
  updateMyData,
}) {
  return (
    <input
      type="checkbox"
      checked={value}
      onChange={() => updateMyData(index, id, !value)}
      data-testid={getTestId(`Table-${pascalize(id)}-Checkbox-${index}`)}
    />
  );
}

CheckboxCell.propTypes = {
  value: PropTypes.bool,
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  updateMyData: PropTypes.func.isRequired,
};

CheckboxCell.defaultProps = {
  value: false,
};

function DateCell({ value }) {
  return (
    <div className="date-cell">{formatDate(new Date(value))}</div>
  );
}

function TruncatedTextCell({ value }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const displayText = value.split(" ").slice(0, 2).join(" ") + (value.split(" ").length > 2 ? "..." : "");

  return (
    <div className="truncated-text-cell" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <p className="text">{displayText}</p>
      {isHovered && <div className="tooltip">{value}</div>}
    </div>
  );
}

function MultipleValueCell({ value }) {
  return (
    <div className="lookup-cell">{value.map((v) => v.label).join(", ")}</div>
  );
}

function LookupCell({ value }) {
  if (!value) return <div />;

  return value?.value ? (
    <div className="link-cell">
      <Link
        to={handleDrawer(
          value.value,
          null,
          false,
          "dynamicObjectDrawers"
        )}
      >
        <TruncatedTextCell value={value.label} type="lookup" />
      </Link>
    </div>
  ) : (
    <TruncatedTextCell value={value} />
  );
}

LookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LookupCell.defaultProps = {
  value: "",
};

export const getQuotationTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Number",
    accessor: "quotation.label",
  },
  {
    Header: "Building",
    accessor: "building.label",
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: MultipleValueCell
  },
  {
    Header: "Contract Amount",
    accessor: "totalContractAmount",
  }
];

export const getReservationTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Number",
    accessor: "reservation.label",
  },
  {
    Header: "Building",
    accessor: "building.label",
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: MultipleValueCell
  },
  {
    Header: "Reservation Amount",
    accessor: "reservationAmount",
  }
];

export const getMatchingUnitColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
    collapse: true
  },
  {
    Header: "Unit Number",
    accessor: "number",
    collapse: true
  },
  {
    Header: "Unit Type",
    accessor: "unitType",
  },
  {
    Header: "Unit Name",
    accessor: "name",
  },
  {
    Header: "Building",
    accessor: "building.name",
  }
];

export const getRevenueRecognitionTable = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Contract",
    accessor: "contract.label",
  },
  {
    Header: "Tenant",
    accessor: "tenant.label",
  },
  {
    Header: "Building",
    accessor: "building.label",
    Cell: TruncatedTextCell,
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: MultipleValueCell
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  }
];

export const getBlanketAgreementTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Vendor",
    accessor: "vendor.label",
  },
  {
    Header: "Expense",
    accessor: "expense.label",
  },
  {
    Header: "Asset",
    accessor: "asset.label",
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  }
];

export const getAssetDetailTable = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Asset",
    accessor: "asset.label",
  },
  {
    Header: "Cost",
    accessor: "cost",
  },
  {
    Header: "Purchase Date",
    accessor: "purchaseDate",
    Cell: DateCell
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Depreciation For The Period",
    accessor: "depreciationForPeriod",
  },
  {
    Header: "Book Value",
    accessor: "bookValue",
  }
];

export const getRevaluationAssetDetailTable = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Asset",
    accessor: "asset.label",
  },
  {
    Header: "Previous Value",
    accessor: "previousValue",
  },
  {
    Header: "Revalued Value",
    accessor: "revaluedValue",
  },
  {
    Header: "Description",
    accessor: "description",
  }
];

export const getContractTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Number",
    accessor: "contract.label",
  },
  {
    Header: "Building",
    accessor: "building.label",
    Cell: TruncatedTextCell,
  },
  {
    Header: "Unit",
    accessor: "unit",
    Cell: MultipleValueCell
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  },
  {
    Header: "Open Balance",
    accessor: "openBalance",
  }
];

export const getPaymentsTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Due Date",
    accessor: "paymentDate",
    Cell: DateCell
  },
  {
    Header: "ChequeNo",
    accessor: "chequeNo",
  },
  {
    Header: "Contract",
    accessor: "contract.label",
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Description",
    accessor: "description"
  }
];

export const getChequePaymentsTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Due Date",
    accessor: "paymentDate",
    Cell: DateCell
  },
  {
    Header: "ChequeNo",
    accessor: "chequeNo",
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  },
  {
    Header: "Tenant",
    accessor: "tenant.label",
  },
  {
    Header: "Cheque Bank",
    accessor: "bank.label",
  },
  {
    Header: "Contract",
    accessor: "contract.label",
  },
  {
    Header: "Issue Date",
    accessor: "chequeDate",
    Cell: DateCell
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "PR Ref",
    accessor: "paymentReceipt.label",
  },
  {
    Header: "PDC Ref",
    accessor: "number",
  },
  {
    Header: "Description",
    accessor: "description"
  }
];

export const getClearChequePaymentsTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Due Date",
    accessor: "paymentDate",
    Cell: DateCell
  },
  {
    Header: "ChequeNo",
    accessor: "chequeNo",
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  },
  {
    Header: "Tenant",
    accessor: "tenant.label",
  },
  {
    Header: "Cheque Bank",
    accessor: "bank.label",
  },
  {
    Header: "Contract",
    accessor: "contract.label",
  },
  {
    Header: "Issue Date",
    accessor: "chequeDate",
    Cell: DateCell
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "PR Ref",
    accessor: "paymentReceipt.label",
  },
  {
    Header: "PDC Ref",
    accessor: "number",
  },
  {
    Header: "clearing Account",
    accessor: "account.label"
  },
  {
    Header: "Clearance Date",
    accessor: "clearanceDate",
    Cell: DateCell
  },
  {
    Header: "Description",
    accessor: "description"
  }
];

export const getRentalDetailTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Contract",
    accessor: "contract.label",
  },
  {
    Header: "ChequeNo",
    accessor: "chequeNo",
  },
  {
    Header: "Payment Date",
    accessor: "paymentDate",
    Cell: DateCell
  },
  {
    Header: "Total Amount",
    accessor: "totalAmount",
  },
];

export const getRentalInvoiceTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Invoice",
    accessor: "rentalInvoice.label",
  },
  {
    Header: "Total Amount",
    accessor: "total",
  },
  {
    Header: "Date",
    accessor: "createdAt",
    Cell: DateCell
  },
];

export const getSupplierQuotationTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Quotation",
    accessor: "quotation.label",
  },
  {
    Header: "request For Quotation",
    accessor: "requestForQuotation.number",
  },
  {
    Header: "Total Amount",
    accessor: "total",
  },
];

export const getPurchaseOrderTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Purchase Order",
    accessor: "purchaseOrder.label",
  },
  {
    Header: "Total Amount",
    accessor: "total",
  },
];

export const getPurchaseInvoiceTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Purchase Invoice",
    accessor: "number",
  },
  {
    Header: "Total Amount",
    accessor: "total",
  },
  {
    Header: "Open Balance",
    accessor: "openBalance",
  },
  {
    Header: "Status",
    accessor: "purchaseInvoiceStatus",
  },
];

export const getExpenseTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Expense",
    accessor: "number",
  },
  {
    Header: "Total Amount",
    accessor: "total",
  },
  {
    Header: "Open Balance",
    accessor: "openBalance",
  },
  {
    Header: "Invoice No.",
    accessor: "invoiceNumber",
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
  },
  {
    Header: "Status",
    accessor: "expenseStatus",
  },
];

export const getDebitReconciliationColumns = () => [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Cheque #",
    accessor: "chequeNo",
  },
  {
    Header: "Payee",
    accessor: "payee",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
];

export const getCreditReconciliationColumns = () => [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Cheque #",
    accessor: "chequeNo",
  },
  {
    Header: "Payee",
    accessor: "payee",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
];

export const getProformaInvoiceTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Invoice #",
    accessor: "proformaInvoice.label",
  },
  {
    Header: "Date",
    accessor: "proformaInvoiceDate",
  },
  {
    Header: "Amount",
    accessor: "total",
  },
];

export const getFacilityContractTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Facility Contract #",
    accessor: "facilityContract.label",
  },
  {
    Header: "Date",
    accessor: "facilityContractDate",
  },
  {
    Header: "Amount",
    accessor: "total",
  },
];

export const getGeneralInvoiceTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Invoice #",
    accessor: "generalInvoice.label",
  },
  {
    Header: "Date",
    accessor: "invoiceDate",
  },
  {
    Header: "Amount",
    accessor: "total",
  },
];

export const getGeneralReceiptTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Receipt #",
    accessor: "generalReceipt.label",
  },
  {
    Header: "Date",
    accessor: "receiptDate",
  },
  {
    Header: "Amount",
    accessor: "total",
  },
];

export const getContractTerminationTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Contract #",
    accessor: "contract.label",
  },
  {
    Header: "Contract Termination #",
    accessor: "contractTermination.label",
  },
  {
    Header: "Refund Amount",
    accessor: "total",
  },
];

export const getPettyCashTransactionColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Date",
    accessor: "transactionDate",
  },
  {
    Header: "Receipt No.",
    accessor: "receiptNo",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Description",
    accessor: "description",
  },
];

export const getSubmittedQuotationTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Supplier",
    accessor: "supplier",
    Cell: LookupCell
  },
  {
    Header: "Quotation",
    accessor: "quotation",
    Cell: LookupCell
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Approved",
    accessor: "isApproved"
  }
];

export const getAMCInvoiceTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "AMC Invoice #",
    accessor: "number",
  },
  {
    Header: "Supplier Invoice #",
    accessor: "supplierInvoiceNr",
  },
  {
    Header: "Due Date",
    accessor: "dueDate",
  },
  {
    Header: "Supplier Invoice Date",
    accessor: "supplierInvoiceDate",
  },
  {
    Header: "Invoice Received Date",
    accessor: "invoiceReceivedDate",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Open Balance",
    accessor: "openBalance",
  },
  {
    Header: "Status",
    accessor: "amcInvoiceStatus",
  },
];

export const getAMCInvoiceFormTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Blanket Agreement #",
    accessor: "number",
  },
  {
    Header: "Subtotal",
    accessor: "subtotal",
  },
  {
    Header: "Tax Amount",
    accessor: "taxAmount",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Description",
    accessor: "description",
  },
];

export const getAMCInvoicePaymentTableColumns = () => [
  {
    Header: "Select",
    accessor: "isSelected",
    Cell: CheckboxCell,
  },
  {
    Header: "Blanket Agreement #",
    accessor: "number",
  },
  {
    Header: "Asset Name",
    accessor: "assetName",
  },
  {
    Header: "Asset Number",
    accessor: "assetNumber",
  },
  {
    Header: "Subtotal",
    accessor: "subtotal",
  },
  {
    Header: "Tax Amount",
    accessor: "taxAmount",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Description",
    accessor: "description",
  },
];
