import {
  useState, useRef, useMemo
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import {
  Input, Button, Pagination, FormControl
} from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import qs from "qs";
import { SvgIcon, CustomizedDateRangePicker, IconButton } from "@/components/common";
import { getDailyTransactionDetailRows, getDailyTransactionDetailColumns } from "./daily-transaction-detail-statement";
import { getDailyTransactionDetail } from "@/api/finance/reportApi";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";
import { formatApiPayloadDate } from "@/utils/helpers";

const perPageOptions = [
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "150", value: 150 },
];

function ReportDailyTransactionDetail({ activeCompany }) {
  const ref = useRef();
  const [columnsData, setColumnsData] = useState(
    getDailyTransactionDetailColumns(ref?.current?.clientWidth)
  );
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const columns = useMemo(() => columnsData.filter((c) => c.value), [columnsData]);
  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPerPage(pageSize);
  };
  const formatFilters = () => {
    const filterData = {
      CompanyName: activeCompany.name,
      StartDate: formatApiPayloadDate(dateRange[0]),
      EndDate: formatApiPayloadDate(dateRange[1])
    };

    return filterData;
  };
  const exportExcel = () => window.open(`${appSettings.baseUrl}/reports/export-query-report/daily-transaction-detail?CompanyId=${activeCompany.id}&${qs.stringify(formatFilters())}`, "_blank", "noopener,noreferrer");

  const { data: dailyTransactionDetailData, isLoading } = useQuery(["daily-transaction-detail", currentPage, perPage.value, dateRange], () => getDailyTransactionDetail({
    pageNumber: currentPage,
    pageSize: perPage.value,
    orderBy: "Week",
    ...formatFilters()
  }));
  const totalCount = useMemo(() =>
    dailyTransactionDetailData?.totalCount || 0, [dailyTransactionDetailData]);

  const formatReport = (transaction) => {
    if (filterText) {
      return transaction.filter((obj) => obj.contractNumber.toLowerCase().includes(filterText.toLowerCase()));
    }
    return transaction;
  };

  const handleColumnResize = (ci, width) => {
    setColumnsData((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  };

  const rows = useMemo(
    () =>
      getDailyTransactionDetailRows({
        data: {
          data: formatReport(dailyTransactionDetailData?.data) || [],
        },
        columns,
      }),
    [dailyTransactionDetailData, columns, filterText]
  );

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-6">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-3 general-date-range">
          <FormControl>
            <CustomizedDateRangePicker
              name="dateRange"
              value={dateRange}
              onChange={(value) => setDateRange(value)}
              required
            />
          </FormControl>
        </div>
        <div className="col-md-3 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={() => exportExcel()}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
        </div>
      </div>
      <div className="react-grid-container">
        { !isLoading ? (
          <ReactGrid
            rows={rows}
            columns={columns}
            enableFillHandle
            enableRangeSelection
            onColumnResized={handleColumnResize}
            disableVirtualScrolling
          />
        ) : <TableSkeleton /> }
        {!isLoading && (
        <Pagination
          className="dashboard-pagination"
          options={perPageOptions}
          perPage={perPage}
          onSelectChange={(val) => handlePagination(currentPage, val)}
          pageRangeDisplayed={3}
          pageNo={currentPage}
          handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
          showResults
          offset={0}
          totalItems={totalCount}
          pageCount={Math.ceil(totalCount / perPage.value)}
          reactPaginateProps={{
            previousLabel: <span className="material-icons">&#xe5cb;</span>,
            nextLabel: <span className="material-icons">&#xe5cc;</span>,
            forcePage: currentPage - 1,
          }}
        />
        )}
      </div>
    </div>
  );
}

export default ReportDailyTransactionDetail;
