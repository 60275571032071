import { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { kebabCase, startCase } from "lodash";
import { camelize, pascalize } from "humps";
import { Button, Loader, Form } from "@hydra/atom/components";

import pluralize from "pluralize";
import { Modal, FormLeftHeader } from "@/components/common";
import { ModalFooter } from "@/components/modals";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import {
  createDynamicObjectRecord,
  updateDynamicObjectRecord,
  getDynamicObjectRecordById,
  getDynamicObjectRecords
} from "@/api/dynamic/dynamicObjectNameApi";
import { createTask, cancelWorkflow } from "@/api/workflow/workflowApi";
import { selectActiveApp } from "@/store/appSlice";
import workflowMap from "@/utils/maps/workflowMap";
import { prepareFieldValue, renderField, prepareFieldState } from "@/utils/dynamic/helpers";
import appSettings from "@/settings";
import request from "@/utils/api/helpers";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import showToast from "@/utils/toast/helpers";
import { formatApiPayloadDate, formatDecimalValues } from "@/utils/helpers";
import { calculateContractRefund } from "@/utils/leasing/helpers";
import { useUser } from "@/hooks";
import { checkDateInAccountingPeriod } from "@/api/finance/accountingPeriodApi";

const calculatedContractDetails = (contract, contractTerminationDate) => {
  const contractData = calculateContractRefund(contract, contractTerminationDate);
  contractData.rentAmountForStayedPeriod = contractData.rentForStayedPeriod;
  contractData.receivedRentAmount = contractData.totalRentReceived;
  contractData.receivedUtilityAmount = contractData.totalUtilityAmountReceived;
  contractData.receivedVatAmount = contractData.totalVatReceived;
  return contractData;
};

const calculateContractRenewalDetails = (contract) => {
  const { unit } = contract;
  const newRentAmount = unit.reduce(
    (total, currentValue) =>
      total + Number(currentValue.newRentAmount || currentValue.totalRentAmount),
    0
  );
  const contractData = {};
  contractData.actualRentAmount = newRentAmount;
  contractData.prevRentAmount = contract.annualAmount;
  const changedAmount = newRentAmount - contractData.prevRentAmount;
  contractData.increasedRentAmount = Math.abs(changedAmount);
  contractData.prevUtilityAmount = contract.totalUtilityCharge;
  contractData.actualUtilityAmount = contract.actualUtilityAmount || contract.totalUtilityCharge;

  if (changedAmount === 0) {
    contractData.rentChangeType = {
      label: "No Change",
      value: "NoChange",
    };
  } else if (changedAmount > 0) {
    contractData.rentChangeType = {
      label: "Increase",
      value: "Increase",
    };
  } else {
    contractData.rentChangeType = {
      label: "Decrease",
      value: "Decrease",
    };
  }

  contractData.rentChangePercentage = formatDecimalValues(
    (Math.abs(changedAmount) / contractData.prevRentAmount) * 100
  );
  return contractData;
};

function DynamicObjectActionModal({
  isOpen, onClose, action, objectName, id
}) {
  const [state, setState] = useState({});
  const queryClient = useQueryClient();
  const activeApp = useSelector(selectActiveApp);
  const { user } = useUser();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const setFieldValue = (key, value) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  const onSuccess = () => {
    showToast("Action completed successfully", "success");
    queryClient.invalidateQueries({
      queryKey: [kebabCase(objectName)],
    });
    navigate(`/${kebabCase(activeApp?.value)}/${kebabCase(pluralize(objectName))}`);
    onClose();
  };

  const { data, isLoading: isLoadingSchema } = useQuery(
    ["dynamic-object-camelized-schema", kebabCase(objectName)],
    () => getDynamicObjectByNameWithCamelizedFieldNames(objectName),
    {
      enabled: isOpen,
    }
  );

  const { data: moveOutRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("MoveOutRequestObjectName")), id],
    () =>
      getDynamicObjectRecords(dynamicObjectMap.get("MoveOutRequestObjectName"), {
        takePage: 1,
        limitPage: 1,
        sortBy: "CreatedAt",
        sortType: "DESC",
        queryMode: "Deep",
        contract: id,
      }),
    {
      enabled: Boolean(action?.name === "Terminate")
    }
  );

  const actionFieldNames = action?.fields.map(({ fieldName }) => camelize(fieldName)) ?? [];

  const fields = data?.document?.filter((field) => actionFieldNames.includes(field.camelizedName));

  const actionFormFieldNames = useMemo(
    () =>
      (action?.fields || [])
        .filter(({ fieldName, showField }) => showField !== false && camelize(fieldName))
        .map(({ fieldName }) => camelize(fieldName)) ?? [],
    [action]
  );

  const formFields = useMemo(
    () =>
      data?.document
        ?.filter((field) => actionFormFieldNames.includes(field.camelizedName))
        .map((item) => ({
          ...item,
          required: objectName === dynamicObjectMap.get("ServiceRequestObjectName") ? true : item.required,
        })),
    [data, actionFormFieldNames]
  );

  const { data: recordData, isLoading: isLoadingRecord } = useQuery(
    [kebabCase(objectName), id],
    () => getDynamicObjectRecordById(objectName, id),
    {
      enabled: isOpen,
    }
  );

  useEffect(() => {
    if (recordData) {
      if (formFields?.length) {
        const initialState = {};

        formFields.forEach((field) => {
          const actionFieldIndex = actionFieldNames.findIndex(
            (fieldName) => fieldName === field.name
          );
          const actionField = action.fields[actionFieldIndex];

          if (actionField.value === "@current") {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            initialState[field.camelizedName] = prepareFieldState({
              field,
              value: currentDate,
            });
          } else if (actionField.value === "true") {
            initialState[field.camelizedName] = true;
          } else if (actionField.value === "false") {
            initialState[field.camelizedName] = false;
          } else {
            initialState[field.camelizedName] = prepareFieldState({
              field,
              value: recordData[field.camelizedName],
            });
          }
        });

        if (action?.name.startsWith("Cancel") && recordData.transactionDate) {
          initialState.transactionDate = new Date(recordData.transactionDate);
        }

        if (objectName === dynamicObjectMap.get("ContractObjectName") && action?.name === "Terminate") {
          const details = calculatedContractDetails(
            recordData,
            initialState.contractTerminationDate
          );
          setState({ ...initialState, ...details, contractInitiationType: "New" });
        } else if (objectName === dynamicObjectMap.get("ContractObjectName") && action?.name === "Review Renewal Contract") {
          const details = calculateContractRenewalDetails(recordData);
          initialState.isRenewingContract = true;
          setState({ ...initialState, ...details });
        } else if (objectName === dynamicObjectMap.get("ContractObjectName") && action?.name === "Discontinue") {
          initialState.continuityMode = "Continued";
          setState({ ...initialState });
        } else {
          setState(initialState);
        }
      }
    }
  }, [recordData, formFields]);

  useEffect(() => {
    if (
      objectName === dynamicObjectMap.get("ContractObjectName") &&
      action?.name === "Terminate" &&
      state?.contractTerminationDate
    ) {
      const details = calculatedContractDetails(recordData, state.contractTerminationDate);
      setState({ ...state, ...details });
    }
  }, [state?.contractTerminationDate]);

  const createMutation = useMutation(({ dynamicObjectName, dataObject }) =>
    createDynamicObjectRecord(dynamicObjectName, dataObject)
  );

  const updateMutation = useMutation(
    ({ recordId, dataObject, dynamicObjectName }) =>
      updateDynamicObjectRecord(dynamicObjectName, recordId, dataObject),
    {
      onError: (_, variables) => {
        showToast(`Could not update ${variables.dynamicObjectName}. Try again!`, "error");
      },
    }
  );

  const createTaskMutation = useMutation(
    ({
      recordId, dynamicObjectName, workflowName, workflowStep, objectEventType
    }) =>
      createTask({
        objectName: pascalize(dynamicObjectName),
        workflowStep,
        objectEventType,
        workflowName,
        recordId,
      }),
    {
      onError: () => {
        showToast("Could not assign task to supervisor. Try again!", "error");
      },
    }
  );

  const cancelWorkflowMutation = useMutation(
    ({
      recordId, dynamicObjectName, workflowName, workflowStep, objectEventType
    }) =>
      cancelWorkflow({
        objectName: pascalize(dynamicObjectName),
        workflowStep,
        objectEventType,
        workflowName,
        recordId,
      }),
    {
      onError: () => {
        showToast("Could not cancel task to supervisor. Try again!", "error");
      },
    }
  );

  const updateData = async (payload) => {
    try {
      const res = await updateMutation.mutateAsync({
        dynamicObjectName: objectName,
        recordId: id,
        dataObject: payload,
      });

      if (res?.status === 200 && action?.name === "Assign Supervisor") {

        await createTaskMutation.mutateAsync({
          dynamicObjectName: objectName,
          recordId: id,
          workflowName: workflowMap.get("ServiceRequest"),
          workflowStep: "Service Request",
          objectEventType: "Updated",
        });
      }

      if (res?.status === 200 && action?.name === "Reassign Supervisor") {
        await cancelWorkflowMutation.mutateAsync({
          dynamicObjectName: objectName,
          recordId: id,
          workflowName: workflowMap.get("ServiceRequest"),
        });

        await createTaskMutation.mutateAsync({
          dynamicObjectName: objectName,
          recordId: id,
          workflowName: workflowMap.get("ServiceRequest"),
          workflowStep: "Service Request",
          objectEventType: "Updated",
        });
      }

      if (res?.status === 200 && action?.name === "Escalate to Supervisor") {
        await createTaskMutation.mutateAsync({
          dynamicObjectName: objectName,
          recordId: id,
          workflowName: workflowMap.get("SREscalation"),
          workflowStep: "Service Request",
          objectEventType: "Updated",
        });
      }

      // If reservation is fully collected and type of reservation is refundable, start the refund
      if (
        res?.status === 200 &&
        action?.objectName === dynamicObjectMap.get("ReservationObjectName") &&
        action?.name === "Cancel"
      ) {
        if (
          ["Paid", "ConvertedToContract"].includes(recordData.status) &&
          recordData.type === "Refundable"
        ) {
          await createTaskMutation.mutateAsync({
            dynamicObjectName: objectName,
            recordId: id,
            workflowName: workflowMap.get("CancelReservationFlow"),
            workflowStep: "Approval from General Manager",
            objectEventType: "Submitted",
          });
        }
      }

      if (res?.status === 200 && action?.name === "Transfer Contract") {
        await createMutation.mutateAsync({
          dynamicObjectName: dynamicObjectMap.get("ContractPaymentDetailObjectName"),
          dataObject: {
            detailId: id,
            paymentDate: formatApiPayloadDate(new Date()),
            paymentMethod: "Cash",
            amount: payload.transferFee,
            tax: null,
            taxAmount: "",
            totalAmount: payload.transferFee,
            // Hidden fields
            openBalance: payload.transferFee,
            paymentStatus: "Pending",
            paymentType: "TransferFee",
            description: "Transfer Fee",
          },
        });
      }

      onSuccess();
    } catch (error) {
      showToast(`Could not update ${objectName}. Please try again`, "error");
    }
  };

  const performAction = async (payload = {}) => {
    setIsLoading(true);
    const { apiAction, apiUrl } = action;
    const { baseUrl } = appSettings;
    const requestParams = {
      url: `${baseUrl}/${apiUrl}?${objectName}=${id}`,
      method: apiAction,
    };

    switch (apiAction) {
      case "Post":
      case "Patch":
      case "Put":
        requestParams.headers = {
          "Content-type": "application/json",
        };

        requestParams.data = payload;
        break;

      default:
        break;
    }

    try {
      await request(requestParams);
      onSuccess();
    } catch (error) {
      showToast(`Could not ${action.name}. Please try again`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    // Check if move out request already exists
    if (action.name === "Terminate" && objectName === dynamicObjectMap.get("ContractObjectName")) {
      if (moveOutRequestData && moveOutRequestData?.data.length) {
        const moveOutRequest = moveOutRequestData.data[0];
        if (moveOutRequest.status === "Approved") {
          showToast(`Move out request ${moveOutRequest.number} already exists for contract`, "error");
          onClose();
          return;
        }
      }
    }

    const dataObject = {};
    action?.fields.forEach((actionField) => {
      const selectedField = fields.find((f) => f.camelizedName === camelize(actionField.fieldName));

      if (actionField?.value && ["@Value", "@current"].includes(actionField?.value)) {
        dataObject[selectedField.name] = prepareFieldValue(selectedField, {
          [selectedField.name]: state[selectedField.name],
        });
      } else if (actionField?.value) {
        if (selectedField.objectFieldType === "Checkbox") {
          // Note: Boolean is coming as string
          dataObject[selectedField.name] = actionField?.value === "true";
        } else {
          dataObject[selectedField.name] = actionField?.value;
        }
      } else if (action.name === "Cancel Discontinuation") {
        dataObject[selectedField.name] = null;
      }
    });

    switch (action.actionType) {
      case "Update":
        if (typeof recordData?.transactionDate === "string") {
          const response = await checkDateInAccountingPeriod(recordData.transactionDate);

          if (response.status !== "Open") {
            showToast("Cancellation date is outside of the current open accounting period", "error");
            return;
          }
        }
        updateData(dataObject);
        break;

      case "Api":
        performAction(dataObject);
        break;

      default:
        break;
    }
  };

  const renderFormFields = () => (
    <div className="row">
      <div className="col-md-3">
        <FormLeftHeader title={action?.name} />
      </div>
      <div className="col-md-9">
        {formFields.map((field, index) => (
          <div key={`action-field-${index}`} className="mb-3">
            {renderField({
              index,
              field,
              parentObjectName: pascalize(objectName),
              parentId: id,
              state,
              onChange: (key, value) => setFieldValue(key, value),
              user,
            })}
          </div>
        ))}
      </div>
    </div>
  );

  const renderModalContent = () => {
    if (actionFormFieldNames.length) {
      return renderFormFields();
    }

    return <p>{`Do you want to ${action?.name} the ${objectName}?`}</p>;
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      rootClassName="center-vertically"
      className="dynamic-object-action-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      {isLoadingSchema || isLoadingRecord ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-12 core-modal-content">
            <Form onSubmit={handleSubmit} className="extend-modal-form">
              <div className="dynamic-model-heading">
                <h2>{startCase(action?.objectName)}</h2>
              </div>
              {action?.objectName === dynamicObjectMap.get("ServiceRequestObjectName") ? (
                <div className="dynamic-model-heading">
                  <h3>
                    Select the supervisor to assign this Service Request to, they will be notified
                    automatically.
                  </h3>
                </div>
              ) : null}
              <div className="dynamic-model-sub-heading">{renderModalContent()}</div>
              <ModalFooter bordered={false}>
                <Button small bordered onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  small
                  loading={updateMutation.isLoading || createTaskMutation.isLoading || isLoading}
                >
                  Confirm
                </Button>
              </ModalFooter>
            </Form>
          </div>
        </div>
      )}
    </Modal>
  );
}

DynamicObjectActionModal.propTypes = {
  objectName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

DynamicObjectActionModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default DynamicObjectActionModal;
