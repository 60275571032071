import {
  DynamicReport
} from "@/components/dynamic";
import { getOutputVATRows, getOutputVATColumns } from "./output-vat-statement";
import { getOutputVat } from "@/api/finance/reportApi";

const outputVATFilters = {
  RecordType: "*",
};

const outputVATFilterOptions = [
  { key: "recordType", apiKey: "RecordType", keyType: "label" },
];

export default function ReportOutputVAT({ activeCompany }) {
  return (
    <DynamicReport
      reportName="vat-input"
      activeCompany={activeCompany}
      columnsDataProvider={getOutputVATColumns}
      rowsDataProvider={getOutputVATRows}
      fetchData={getOutputVat}
      exportUrl="reports/export-query-report/vat-output"
      filtersInitialState={outputVATFilters}
      filterOptions={outputVATFilterOptions}
      orderBy="CompanyName"
    />
  );
}
